import React,{useEffect, useState} from "react";
import Header from "../../ReuseComponents/Header/Header";
import { Button, Card, Grid, IconButton, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ScanImage from "../../../Assets/images/QrScan/qrscanimg.svg"



export default function VerifyAppointment() {


    
  
  

  return (
   <>
      <Header />
      <Grid container>
      <Grid item xs={12}>
        <Typography sx={{ color: "#000000", fontWeight: 600, fontSize: "20px",marginTop:"10px" }}>
          Scan Code
        </Typography>
      </Grid>
      <Grid item xs={12} mt={8}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Card
            sx={{
              width: { xs: "80%", sm: "80%", md: "80%", lg: "25%" },
              height:"45vh",
              borderRadius: "10px 10px 10px 10px",
            }}
          >
            <Box
              sx={{
                height: "60px",
                backgroundColor: "#C2FFEA",
                borderRadius: "10px 10px 0px 0px",
              }}
            >
              <IconButton
                sx={{
                  position: "relative",
                  top: "30px",
                  left: { xs: "50%", sm: "50%", md: "50%", lg: "50%" },
                  transform: "translateX(-50%)", // Center the button horizontally
                  backgroundColor: "#41BA8F",
                  height: "54px",
                  width: "55px",
                  "&:hover": { backgroundColor: "#41BA8F" },
                  cursor: "default",
                }}
              >
                <img src={ScanImage} alt='scanicon' />
              </IconButton>
            </Box>
            <Box sx={{ marginTop: "40px",display:"flex",justifyContent:"center"}}>
                <Box>
              <Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center"  }}>
               Verify
              </Typography> 
              <Typography sx={{ color: "#231F20", fontWeight: 600, fontSize: "15px",display:"flex",justifyContent:"center" }}>
               Appointment
              </Typography>             
              <Box sx={{marginTop:"13px"}} >
                <input
                  id=""
                  variant="outlined"
                  placeholder="Enter Mobile Number"
                  size="small"
                  name="mobile_number"
                  style={{
                    width: "170px", // Make the input full width
                    height: "40px",
                    fontSize: "14px",
                   
                    
                  }}
                />
              </Box>
              </Box>
              </Box>
             
            <Box sx={{ marginTop: "10px" }}>
             
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <Button
                  sx={{
                    mt: 3,
                    mb: 2,
                    mr: 2,
                    backgroundColor: "#D13C3C",
                    color: "#FFFFFF",
                    fontSize: "13px",
                    border: "1px solid #FFF",
                    borderRadius: "50px",
                    height: "30px",
                    "&:hover": { background: "#D13C3C" },
                    width: "100px",
                    fontWeight: 600,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: "#41BA8F",
                    color: "#FFFFFF",
                    border: "1px solid #FFF",
                    fontSize: "13px",
                    borderRadius: "50px",
                    height: "30px",
                    "&:hover": { background: "#41BA8F" },
                    width: "100px",
                    fontWeight: 600,
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Card>
        </Box>
      </Grid>
    </Grid>
      </>
  );
}
