import { createSlice } from "@reduxjs/toolkit";
let currentdate = new Date();
    // Set it to the start of the day
    currentdate = currentdate.setHours(0, 0, 0, 0);
const initialState = {
    date:  new Date(currentdate).toISOString()
}

export const dateFilter = createSlice({
    name:"storeData",
    initialState,
    reducers:{
        updateActualDateData:(state, action) =>{
            state.date = action.payload
            console.log(state);
        }
    }
})

export const { updateActualDateData } = dateFilter.actions;
export default dateFilter.reducer