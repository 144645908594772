import { MenuItem, Select, styled } from "@mui/material";

const CustomizedMenuItem = styled(MenuItem)({
    color: '#000',
    height: "30px",
    padding: "2px 10px",
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#000',
    },
    '&.MuiMenuList-root': {
        borderColor: '#C4C4C4',
        padding: "2px 10px",
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#C4C4C4',
    },
});

export function BasicMenuItem(props) {
    const { classes, children, sx, startIcon, endIcon, inputProps, readOnly, error } = props;

    return (
        <CustomizedMenuItem
            {...props}
            fullWidth
            variant="outlined"
            sx={{ ...(sx || {}) }}
            inputProps={{
                ...(inputProps || {}),
                sx: { padding: "10px 12px", fontSize: "14px", ...(inputProps?.sx || {}) },
                readOnly: readOnly || false
            }}
            size="small"
        >{children}</CustomizedMenuItem>
    );
}