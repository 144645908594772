import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    booked_data: []  
}

export const headerData = createSlice({
    name:"storeData",
    initialState,
    reducers:{
        updateHeaderData:(state, action) =>{
            state.booked_data = action.payload
            console.log(state);
        },
        clearHeaderData:(state, action) =>{
            state.booked_data = initialState
            console.log(state);
        }
    }
})

export const { updateHeaderData, clearHeaderData } = headerData.actions;
export default headerData.reducer