import DashboardIcon from "../Assets/navbar/dashbordIconNew.svg";
import DashboardActive from "../Assets/mobileNavbar/dashboard_active.svg";
import dashbordHideIcon from "../Assets/icons/dashbordHideImage.svg"

const NavItemsDashboard = [
     {
      name: "Dashboard",
      icon:DashboardIcon,  
      fadeIcon:dashbordHideIcon,    
      url : "/dashboard",
      key: "DASHBOARD",
      activePage:DashboardActive,
    },   
  ];

  export default NavItemsDashboard