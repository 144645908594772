import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../ReuseComponents/BreadCrumbs/BreadCrumbs";
import { Backdrop, Box, Grid, LinearProgress, Typography, useMediaQuery, useTheme } from "@mui/material";
import QRCode from "react-qr-code";
import CustomizedButtons from "../../ReuseComponents/Buttons/ColorButton";
import Popup from "../../ReuseComponents/Popup/Popup";
import { useNavigate } from "react-router-dom";
import Controller from "../../../Controller/ApiController";
import Header from "../../ReuseComponents/Header/Header";
import { useSelector } from "react-redux";
import {
  BasicMenuItem,
  BasicSelect,
} from "../../ReuseComponents/Select/BasicSelect";
import { ContainedButton } from "../../ReuseComponents/Button/BasicButton";
import dayjs from "dayjs";

export default function QRGenerator() {
  let doctor_id = localStorage.getItem("DoctorUid");
  const [value, setValue] = useState("");
  const [back, setBack] = useState("#FFFFFF");
  const [fore, setFore] = useState("#000000");
  const [size, setSize] = useState(256);
  const navigate = useNavigate();
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [userDataList, setUserDataList] = useState("");
  const [urlLink, setUrlLink] = useState("https://app.qglider.com/");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedDuration, setSelectedDuration] = useState("");
  const [userType, setUerType] = useState("");
  const [generated, setGenerated] = useState(false);
  const userDetails = useSelector((state) => state?.userDetails?.data || {});
  const [slots, setSlots] = useState("");
  const [lineProgressLoading, setLineProgressLoading] = useState(false);

  const navigatePrevious = () => {
    if (generated) {
      setGenerated(false);
      setValue("");
    } else {
      navigate(-1);
    }
  };

  const generateQR = async () => {
    setGenerated(true);
    let query = "";
    if(selectedDuration) { 
      query = "?duration=" + selectedDuration     
    }
    setValue(urlLink + "patientqrscan/" + selectedUser + query);
  };

  console.log(value, "valuefonaeoef")

  const userRequest = async () => {
    try {
      if (!userDetails.admin_uid) {
        return;
      }

      let queryParams = {
        "filter.user_type": "DOCTOR,SCAN",
        "filter.doctor_uid": userDetails.admin_uid,
      };

      if(["DOCTOR", "SCAN"].includes(userDetails.user_type)) {
        queryParams = {
          ...queryParams,
          "filter.user_uid": userDetails?.user_uid || "",
        }
      } else {
        queryParams = {
          ...queryParams,
          "filter.user_uid": userDetails?.user_uid || "",
          "filter.user_uid_type": "MAPED_DOCTOR",
        }
      }

      const response = await Controller.ApiController(
        "GET",
        "/manage_users",
        "",
        queryParams
      );
      console.log(response, "responsemonresponse1");
      if (response.type == "success") {
        console.log(response?.data, "responsemonresponse2");
        setUsers(response?.data || []);
      }
    } catch (e) {
      console.log("User Request: ", e.message);
    }
  };

  useEffect(() => {
    if (userDetails) {
      userRequest();
    }
  }, [userDetails]);

  useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  });

  
  const getSlotTime = async (userUID) => {
    let reqParams = {
        "filter.current_date":
          dayjs(new Date()).startOf("day").toISOString() || "",
      };
    try {
      setLineProgressLoading(true);
      let res = await Controller.ApiController(
        "GET",
        `/get_slot/${userUID}`,
        "?",
        reqParams
      );
      setLineProgressLoading(false);
      console.log(res, "slotTimedata");
      
      if (res && res.data && Array.isArray(res.data)) {
        let slotTimeListdata = [];

          slotTimeListdata = res?.data;
        setSlots(slotTimeListdata || []);

      }else if(res.message){
        setLineProgressLoading(false);
        setSlots([]);
      }
    } catch (error) {
      // handleLoaderClose();
        setLineProgressLoading(false);
      console.log(error, "slotTimedata");
    }
  };
  
  const handleUserChange = (e) => {
    const { value } = e.target;
    setSelectedUser(value);
    let fetchUserType = users.find((val) => val.user_uid == value).user_type;
    if(fetchUserType == "SCAN") {
      getSlotTime(value);
    }
    setUerType(fetchUserType);
    setSelectedDuration("");
  }

  const parseScanDuration = (value) => {

    const hours = Math.floor(value / 60);
    const minutes = value % 60;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    let parseValue = "";

    if(parseInt(formattedHours) > 0) {
      parseValue += parseInt(formattedHours) + " Hr "
    }
    if(parseInt(formattedMinutes) > 0){
      parseValue += parseInt(formattedMinutes) + " Min"
    }
    return parseValue;
  }

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Header />
        </Grid>
      </Grid>
      <BreadCrumbs
        crumbs={["Dashboard", "Generate QR Code"]}
        paths={["/dashboard", "/generateQR"]}
        selected={(crumb) => console.log("hello", crumb)}
      />

      <Box sx={{width: "100%"}} display={"flex"} justifyContent={"center"} >
        <Backdrop
          sx={{ color: "#fff", zIndex: 0, left: {xs: 0, md: "260px"} }}
          open
          invisible={!isMd}
        >
          <Box
            sx={{ backgroundColor: "#fff", padding: {xs: "20px", sm: "50px"}, borderRadius: "10px", textAlign: "center", width: {xs: "320px", sm: "400px"} }}
          >
              <Typography
                fontSize={"20px"}
                mb={3}
                fontFamily={"Poppins"}
                color={"#243665"}
                fontWeight={700}
              >
                {generated ? "Scan QR Code" : "Generate a QR Code here"}
              </Typography>
              {value ? (
                <QRCode
                  title="QGLIDER"
                  value={value}
                  bgColor={back}
                  fgColor={fore}
                  size={size === "" ? 0 : size}
                />
              ) : (
                <><BasicSelect
                  value={selectedUser || "default"}
                  onChange={handleUserChange}
                  sx={{ textAlign: "left", width: "220px" }}
                >
                  <BasicMenuItem value="default" style={{ display: "none" }}>
                    <span style={{ color: "#bbb7b7" }}>Select</span>
                  </BasicMenuItem>
                  {Array.isArray(users) && users.length > 0 ? (
                    users.map((val, i) => (
                      <BasicMenuItem key={i} value={val.user_uid}>
                        {val.full_name}
                      </BasicMenuItem>
                    ))
                  ) : (
                    <BasicMenuItem disabled>No Doctors Available</BasicMenuItem>
                  )}
                </BasicSelect>
                
                {userType == "SCAN" && <BasicSelect
                  value={selectedDuration || "default"}
                  onChange={(e) => setSelectedDuration(e.target.value)}
                  sx={{ textAlign: "left", width: "220px", marginTop: "20px" }}
                >
                  <BasicMenuItem value="default" style={{ display: "none" }}>
                    <span style={{ color: "#bbb7b7" }}>Select</span>
                  </BasicMenuItem>
                  { lineProgressLoading ? 
                      <Box sx={{ width: "100%" }}>
                        <LinearProgress />
                      </Box> : 
                      Array.isArray(slots) && slots.length > 0 ? (
                        slots.map((val, i) => (
                          <BasicMenuItem key={i} value={val}>
                            {parseScanDuration(val)}
                          </BasicMenuItem>
                        ))
                      ) : (
                        <BasicMenuItem disabled>No Doctors Available</BasicMenuItem>
                      )
                  }
                </BasicSelect>}
                </>
              )}

              
              {generated ? (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  mt={6}
                  onClick={navigatePrevious}
                >
                  <CustomizedButtons
                    bgColor={"#1971FF"}
                    color={"#white"}
                    text={"Back"}
                    hoverColor={"#1971FF"}
                    style={{
                      borderRadius: "50px",
                      width: "100%",
                      pl: 6,
                      pr: 6,
                      fontSize: "15px",
                    }}
                  />
                </Box>
              ) : (
                <Box sx={{ textAlign: "center", marginTop: "30px" }}>
                  <ContainedButton
                    disabled={!selectedUser || (userType == "SCAN" && !selectedDuration)}
                    onClick={generateQR}
                  >
                    Generate QR Code
                  </ContainedButton>
                </Box>
              )}
          </Box>
        </Backdrop>
      </Box>
    </div>
  );
}
