import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    access_values: []
}

export const accessPermissionValue = createSlice({
    name:"access_values",
    initialState,
    reducers:{
        accessValue:(state, action) =>{
            state.access_values = action.payload
        },
        
    }
})

export const { accessValue } = accessPermissionValue.actions;

export default accessPermissionValue.reducer