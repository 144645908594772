import { createSlice } from '@reduxjs/toolkit';

const dataRef = () =>{

}
const initialState = {
        appointment:dataRef()
}

export const manageAppointmentSlice = createSlice({
    name:"appointment",
    initialState,
    reducers:{
        manageAppointmentReferesh : (state,action) =>{
            state.appointment = action.payload;
        }
    }
})

export const {manageAppointmentReferesh} = manageAppointmentSlice.actions;
export default manageAppointmentSlice.reducer
