import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { purple } from '@mui/material/colors';





export default function CustomizedButtons({color,bgColor,hoverColor,text,style,disabled,onClick}) {
    const ColorButton = styled(Button)(({ theme }) => ({
        color: color ? color : theme.palette.getContrastText(purple[500]),
        backgroundColor:bgColor ? bgColor : purple[500],
        '&:hover': {
          backgroundColor: hoverColor ? hoverColor : bgColor,
        },
      }));
  return (
      <ColorButton onClick={onClick} disabled={disabled !== undefined ? disabled : false} variant="contained" sx={{textTransform:"capitalize",...style}}>{text ? text : "" }</ColorButton>
  );
}
