import React, { useEffect, useState } from "react";
import "../Forgotpassword/forgotpassword.css";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import GliderLogo from "../../../Assets/qgliderlogo.png";
// import Doctor from "./../Login/Doctor.png";
import Doctor from "../../../Assets/LoginImage/Login_Qg.png";
import LockIcon from "../../../Assets/icons/Lockicon.svg";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from "../../ReuseComponents/Alert/Alertmessage";
import Controller from "../../../Controller/ApiController";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import validationRegExp from "../../../Configuration/ValidationConfig";

export default function ResetPassword() {
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [isPassworTooltipOpen, setIsPasswordTooltipOpen] = useState(false);
  const [isConfirmPasswordTooltipOpen, setIsConfirmPasswordTooltipOpen] =
    useState(false);
  const [errors, setErrors] = useState({});
  const [emptyError, setEmptyError] = useState({});
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    newcheck: "",
    confirmcheck: "",
  });

  const [createPasswordErr, setCreatePasswordErr ] = useState("")
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("")

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  useEffect(() => {}, []);

  const createPasswordFunc = (e) => {
    setFormData({
      ...formData, newcheck: e.target.value
    })
    if(createPasswordErr){
      setCreatePasswordErr("");
    }
  }

  const confirmPasswordFunc = (e) => {
    setFormData({
      ...formData,confirmcheck:e.target.value
    })
    if(confirmPasswordErr){
      setConfirmPasswordErr("")
    }
  }

  const onChangeValue = (event) => {
    const { name, value } = event.target;
    let validInput = true;
    let errorMessage = "";
    if (name === "newcheck") {
      validInput =
        /^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^0-9A-Za-z]).{8,12}$/.test(
          value
        );
      errorMessage = validInput ? "" : "Invalid Password";
    } else if (name === "confirmcheck") {
      validInput =
        /^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^0-9A-Za-z]).{8,12}$/.test(
          value
        );
      errorMessage = validInput ? "" : "Invalid Password";
    }else{
      return;
    }
    //    if(formData.newcheck && formData.confirmcheck){
    //     if(formData.newcheck !== formData.confirmcheck ){
    //         errorMessage =  "New Password and Confirm Password do not match...."
    //     }
    //    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validInput ? "" : errorMessage,
    }));
    setEmptyError((prevErrors) => ({ ...prevErrors, [name]: "" }));
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const validateFields = () => {
    let newCheckErr = false;
    let confirmCheckErr = false;
    console.log(formData.newcheck,"formData.newcheck")
    if (formData.newcheck == "" || formData.newcheck.trim() == "") {
      setCreatePasswordErr("Create password required");
    } else if (formData.newcheck) {
      let validInput = validationRegExp.passwordReg.test(formData.newcheck);
      if (!validInput) {
        setCreatePasswordErr("Invalid Password");
      } else {
        setCreatePasswordErr("");
        newCheckErr = true;
      }
    }

    if (formData.confirmcheck == "" || formData.confirmcheck.trim() == "") {
      setConfirmPasswordErr("Confirm password required");
    } else if (formData.confirmcheck) {
      let validInput = validationRegExp.passwordReg.test(formData.confirmcheck);
      if (!validInput) {
        setConfirmPasswordErr("Invalid Password");
      } else {
        setConfirmPasswordErr("");
        confirmCheckErr = true;
      }
    }

    if (
      formData.newcheck &&
      formData.confirmcheck &&
      formData.newcheck !== formData.confirmcheck
    ) {
      setConfirmPasswordErr("Create Password and Confirm Password does not match..");
      setCreatePasswordErr(true)
      confirmCheckErr = false;
      newCheckErr = false;
    }

    return newCheckErr && confirmCheckErr;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const validation = validateFields();
    // if(!validation){
    //   return;
    // }

    // if(validation){
    try {
      // alert("true")
      const formErrors = {};

      const hasErrors = Object.keys(formErrors).length > 0;
      setErrors(formErrors);

      if (validation) {
        let doctorUid = sessionStorage.getItem("doctor_uid");
        const passwordData = {
          new_password: formData.newcheck,
          confirm_password: formData.confirmcheck,
        };
        let response = await Controller.ApiController(
          "PUT",
          `/doctor/password/${doctorUid}`,
          "",
          passwordData
        );
        console.log(response);
        if (response.type === "success") {
          console.log("Passowrd Successfull Rest");
          Alert("success", "Password created successfully");
          setTimeout(() => {           
            navigate("/login");
          }, 3700);
         
        } else if (response.status === "FAILED") {
          console.log(response.error);
          const errorData = response.error.message;
          Alert("error", errorData);
          console.log("Something wrong");
        }
      }else{
        return;
      }
    } catch (error) {
      console.log(error);
      const errorData = error.response.data.message;
      Alert(
        "error",
        "New password cannot be the same as the Old password",
        errorData
      );
    }
    // }
    // return;
  };

  // const handleKeyDown = (event) => {
  //   if (event.keyCode === 13) {
  //     handleSubmit(event);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("keydown", handleKeyDown);
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);

  return (
    <Grid container component="main" sx={{ height: "100vh", padding: "20px" }}>
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        elevation={6}
        sx={{
          padding: {
            xl: "0px 120px",
            xs: "20px",
            lg: "0px 100px",
            md: "0px 50px",
          },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box>
            <img src={GliderLogo} alt={GliderLogo + ".logo"} width={"155px"} />
          </Box>
          <Box sx={{ marginTop: "50px" }}>
            <h2 sx={{ fontWeight: "600", paddingBottom: "25px" }}>
              Forgot Password
            </h2>
            <Typography
              sx={{ fontSize: "16px", fontWeight: "400", marginTop: "20px" }}
            >
              If you remember your password
            </Typography>
            <Box display={"flex"}>
              <Typography>You can</Typography>
              <Link
                to="/login"
                style={{
                  textDecoration: "none",
                  color: "#0C21C1",
                  fontSize: "16px",
                  fontWeight: "600",
                  marginLeft: "5px",
                }}
              >
                Login here !
              </Link>
            </Box>
          </Box>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 4 }}
            autoComplete="off"
          >
            <TextField
              fullWidth
              id="filled-required-newcheck"
              name="newcheck"
              value={formData.newcheck}
              placeholder="Enter your Password"
              variant="standard"
              inputProps={{
                maxLength: 12, // Set your desired maximum length here
              }}
              // label="Create Password"
              label={
                <span>
                  Create Password *
                  <IconButton
                    size="small"
                    color="#999999"
                    sx={{ marginLeft: 1,cursor:"default" }}
                    onMouseEnter={() => setIsPasswordTooltipOpen(true)}
                    onMouseLeave={() => setIsPasswordTooltipOpen(false)}
                  >
                    <InfoOutlinedIcon />
                  </IconButton>
                  <Tooltip
                    title="Password must have minimum 8 characters and maximum 12. Include at least 1 lowercase, 1 upper case, 1 numeric & 1 special character"
                    arrow
                    open={isPassworTooltipOpen}
                    placement="right"
                  >
                    <span></span>
                  </Tooltip>
                </span>
              }
              error={!!createPasswordErr}
              type={showNewPassword ? "text" : "password"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {" "}
                    <img src={LockIcon} style={{ color: "#000000" }} />{" "}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {" "}
                    <IconButton onClick={handleClickShowNewPassword} edge="end">
                      {showNewPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}{" "}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                "label.Mui-focused": { color: "#999999" },
                "& .MuiInput-underline:after": { borderBottomColor: "#999999" },
                "& input::-ms-reveal, & input::-ms-clear": { display: "none" },
                "& label": {
                  marginTop: "1%",
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#999999",
                },
                "& input::placeholder": { fontSize: "16px", color: "#000000" },
                "@media (max-width: 600px)": {
                  "& input::placeholder": { fontSize: "12px" },
                },
              }}
              onChange={(e) => createPasswordFunc(e)}
            />
            <FormHelperText style={{ color: "#d32f2f" }}>
              {createPasswordErr}
            </FormHelperText>
            <TextField
              fullWidth
              id="filled-required-confirmcheck"
              name="confirmcheck"
              value={formData.confirmcheck}
              placeholder="Enter your Password"
              variant="standard"
              inputProps={{
                maxLength: 12, 
              }}
              // label="Confirm Password"
              label={
                <span>
                  Confirm Password *
                  <IconButton
                    size="small"
                    color="#999999"
                    sx={{ marginLeft: 1,cursor:"default" }}
                    onMouseEnter={() => setIsConfirmPasswordTooltipOpen(true)}
                    onMouseLeave={() => setIsConfirmPasswordTooltipOpen(false)}
                  >
                    <InfoOutlinedIcon />
                  </IconButton>
                  <Tooltip
                    title="Password must have minimum 8 characters and maximum 12. Include at least 1 lowercase, 1 upper case, 1 numeric & 1 special character"
                    arrow
                    open={isConfirmPasswordTooltipOpen}
                    placement="right"
                  >
                    <span></span>
                  </Tooltip>
                </span>
              }
              error={!!confirmPasswordErr}
              type={showConfirmPassword ? "text" : "password"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {" "}
                    <img src={LockIcon} style={{ color: "#000000" }} />{" "}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {" "}
                    <IconButton
                      onClick={handleClickShowConfirmPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}{" "}
                    </IconButton>
                  </InputAdornment>
                ),
                
              }}
              sx={{
                "label.Mui-focused": { color: "#999999" },
                "& .MuiInput-underline:after": { borderBottomColor: "#999999" },
                marginTop: 2,
                "& input::-ms-reveal, & input::-ms-clear": { display: "none" },
                "& label": {
                  marginTop: "1%",
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#999999",
                },
                "& input::placeholder": { fontSize: "16px", color: "#000000" },
                "@media (max-width: 600px)": {
                  "& input::placeholder": { fontSize: "12px" },
                },
              }}
              onChange={(e) => confirmPasswordFunc(e)}
            />
            <FormHelperText style={{ color: "#d32f2f" }}>
              {confirmPasswordErr || errors.validconfirmcheck}
            </FormHelperText>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="submit"
                fullWidth
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "#41BA8F",
                  color: "#FFFFFF",
                  border: "1px solid #FFF",
                  borderRadius: "50px",
                  height: "40px",
                  width: "200px",
                  "&:hover": { background: "#41BA8F" },
                  textTransform: "none",
                }}
              >
                Reset Password
              </Button>
            </div>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={{
          backgroundImage: "url(" + Doctor + ")",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "10px",
        }}
      />
    </Grid>
  );
}
