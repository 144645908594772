import React, { useEffect, useRef, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Typography,
  Select,
  FormControlLabel,
  RadioGroup,
  Radio,
  setRef,
  Card,
  LinearProgress,
  IconButton,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "../Assets/css/custom-class.css";
import validationRegExp from "../Configuration/ValidationConfig";
import PropTypes from "prop-types";
import Controller from "../Controller/ApiController";
import UtilDateTime from "../Constant/utillDateTime";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert } from "../Components/ReuseComponents/Alert/Alertmessage";
import "../Popup/bookappointment.css";
import dayjs from "dayjs";
import CancelAppointmentChild from "./CancelAppointmentChild";
import cancellIcon from "../Assets/images/DeletePopup/cancelIcon.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import {
  BasicMenuItem,
  BasicSelect,
} from "../Components/ReuseComponents/Select/BasicSelect";
import {
  OutlinedInputField,
  OutlinedTextField,
} from "../Components/ReuseComponents/TextField/BasicTextField";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ContainedButton } from "../Components/ReuseComponents/Button/BasicButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "650px !important",
  height: "auto",
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  "@media (min-width: 720px) ": {
    width: "80%",
    // height:"auto",
  },
};

const paymentForList = [
  {
    value: "VACCINE",
    label: "Vaccine",
  },
  {
    value: "CONSULTATION",
    label: "Consultation",
  },
  {
    value: "VACCINE_AND_CONSULTATION",
    label: "Vaccine and Consultation",
  },
];

const modeOfPaymentList = [
  {
    value: "CASH",
    label: "Cash",
  },
  {
    value: "CARD",
    label: "Card",
  },
  {
    value: "UPI",
    label: "UPI",
  },
  {
    value: "UPI_AND_CASH",
    label: "UPI and Cash",
  },
];

export default function PaymentInformation({
  open,
  onClose,
  appointmentData,
  updateCount,
}) {
  const [openCancelModel, setOpenCancelModel] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const userDetails = useSelector((state) => state?.userDetails?.data || {});

  const Location = useLocation();

  const [loaderopen, setloaderOpen] = React.useState(false);
  const handleLoaderClose = () => {
    setloaderOpen(false);
  };
  const handleLoaderOpen = () => {
    setloaderOpen(true);
  };

  const intialFormData = {
    paymentFor: {
      value: "",
      error: false,
    },
    vaccinationFees: {
      value: "",
      error: false,
    },
    consultationFees: {
      value: "",
      error: false,
    },
    modeOfPayment: {
      value: "",
      error: false,
    },
  };

  const [formData, setFormData] = useState(intialFormData);

  const cancelModel = () => {
    setOpenCancelModel(true);
  };

  const cancelModelclose = () => {
    setOpenCancelModel(false);
    onClose();
  };

  const cancelModelcloseChild = () => {
    setOpenCancelModel(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let updateError = formData;
    let formError = false;

    if (formData.paymentFor.value == "") {
      updateError.paymentFor.error = true;
      formError = true;
    }

    if (formData.modeOfPayment.value == "") {
      updateError.modeOfPayment.error = true;
      formError = true;
    }

    if (
      (formData.paymentFor.value == "CONSULTATION" ||
        formData.paymentFor.value == "VACCINE_AND_CONSULTATION") &&
      formData.consultationFees.value == ""
    ) {
      updateError.consultationFees.error = true;
      formError = true;
    }

    if (
      (formData.paymentFor.value == "VACCINE" ||
        formData.paymentFor.value == "VACCINE_AND_CONSULTATION") &&
      formData.vaccinationFees.value == ""
    ) {
      updateError.vaccinationFees.error = true;
      formError = true;
    }

    if (formError) {
      setFormData({ ...updateError });
      return;
    }
    handleLoaderOpen();
    const postData = {
      payment_for: formData.paymentFor.value,
      vaccine_fee: formData.vaccinationFees.value,
      consultation_fee: formData.consultationFees.value,
      method_of_payment: formData.modeOfPayment.value,
    };

    const response = await Controller.ApiController(
      "PUT",
      `/payment/${appointmentData.appointment_uid}`,
      "?",
      postData
    );

    handleLoaderClose();

    if (response.type == "success") {
      Alert("success", response.message);
      updateCount();
      onClose();
    } else {
      Alert("error", response?.error?.message || "Something Went Wrong");
    }
  };

  useEffect(() => {
    if (appointmentData) {
      setFormData((prevData) => ({
        ...prevData,
        paymentFor: {
          value: appointmentData.payment_for,
          error: false,
        },
        vaccinationFees: {
          value: appointmentData.vaccine_fee,
          error: false,
        },
        consultationFees: {
          value: appointmentData.consultation_fee,
          error: false,
        },
        modeOfPayment: {
          value: appointmentData.method_of_payment,
          error: false,
        },
      }));
    }
  }, [appointmentData]);

  const handleChange = (e) => {
    const { name, value, id } = e.target;

    if (
      !validationRegExp.noEmptySpaceStart(value) ||
      (id && !validationRegExp[id](value))
    ) {
      return;
    }

    let updateData = formData;
    if (name == "paymentFor") {
      if (
        value != "VACCINE" &&
        value != "VACCINE_AND_CONSULTATION" &&
        formData.vaccinationFees.value != ""
      ) {
        updateData.vaccinationFees.value = "";
      }

      if (
        value != "CONSULTATION" &&
        value != "VACCINE_AND_CONSULTATION" &&
        formData.consultationFees.value != ""
      ) {
        updateData.consultationFees.value = "";
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      ...updateData,
      [name]: {
        value: value,
        error: false,
      },
    }));
  };

  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={open}
      >
        <Backdrop
          open={open}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <Paper
            className="bookmodal"
            sx={{
              width: { xs: "95%", sm: "95%" },
              maxWidth: "500px", // Set the maximum width for larger screens
              margin: "auto",
            }}
          >
            <Grid sx={{ borderRadius: "15px" }} container>
              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: "#EEF0FA",
                  borderTopLeftRadius: "15px",
                  borderTopRightRadius: "15px",
                }}
              >
                <Box
                  sx={{
                    padding: { md: "10px 25px", xs: "10px 10px" },
                    display: "flex",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#243665",
                      marginLeft: "0.6px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Payment Information for Token #
                    {appointmentData?.token || ""}
                  </Typography>
                </Box>
              </Grid>
              <hr />
              <Grid item md={12} xs={12} sm={12}>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  sx={{ padding: { md: "0px 25px", xs: "0px 10px" } }}
                >
                  <Grid container mb={2} alignItems="center">
                    <Grid item xs={4.5} sm={3} md={5}>
                      <FormLabel
                        htmlFor="paymentFor"
                        sx={{
                          fontSize: "14px",
                          fontWeight: 400,
                          whiteSpace: "nowrap",
                          color: "#000000",
                          // backgroundColor:"red"
                        }}
                      >
                        Payment For
                      </FormLabel>
                    </Grid>
                    <Grid item xs={7.5} sm={9} md={7}>
                      <BasicSelect
                        name="paymentFor"
                        value={formData?.paymentFor?.value || "default"}
                        error={formData?.paymentFor?.error}
                        onChange={handleChange}
                      >
                        <MenuItem value="default" sx={{ display: "none" }}>
                          <span style={{ color: "#bbb7b7" }}>Select</span>
                        </MenuItem>
                        {paymentForList.map((mode, index) => (
                          <BasicMenuItem key={index} value={mode.value}>
                            {mode.label}
                          </BasicMenuItem>
                        ))}
                      </BasicSelect>
                    </Grid>
                  </Grid>
                  <Grid container mb={2} alignItems="center">
                    <Grid item xs={4.5} sm={3} md={5}>
                      <FormLabel
                        htmlFor="vaccinationFees"
                        disabled={
                          !["VACCINE", "VACCINE_AND_CONSULTATION"].includes(
                            formData.paymentFor.value
                          )
                        }
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#000000",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Vaccination Fees
                      </FormLabel>
                    </Grid>
                    <Grid item xs={7.5} sm={9} md={7}>
                      <OutlinedTextField
                        id="number"
                        variant="outlined"
                        placeholder="Enter here"
                        size="small"
                        name="vaccinationFees"
                        error={formData?.vaccinationFees?.error}
                        disabled={
                          !["VACCINE", "VACCINE_AND_CONSULTATION"].includes(
                            formData.paymentFor.value
                          )
                        }
                        inputProps={{
                          maxLength: 6,
                        }}
                        sx={{
                          width: "100%",
                          // height: "40px",
                          fontSize: "14px",
                          "& .MuiOutlinedInput-root": {
                            fontSize: "14px",
                            "&.Mui-focused fieldset": {
                              borderColor: "#C8C7C7", // border color on focus
                            },
                          },
                        }}
                        value={formData?.vaccinationFees?.value || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container mb={2} alignItems="center">
                    <Grid item xs={4.5} sm={3} md={5}>
                      <FormLabel
                        htmlFor="consultationFees"
                        disabled={
                          ![
                            "CONSULTATION",
                            "VACCINE_AND_CONSULTATION",
                          ].includes(formData.paymentFor.value)
                        }
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#000000",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Consultation Fees
                      </FormLabel>
                    </Grid>
                    <Grid item xs={7.5} sm={9} md={7}>
                      <OutlinedTextField
                        id="number"
                        variant="outlined"
                        placeholder="Enter here"
                        size="small"
                        name="consultationFees"
                        disabled={
                          ![
                            "CONSULTATION",
                            "VACCINE_AND_CONSULTATION",
                          ].includes(formData.paymentFor.value)
                        }
                        error={formData?.consultationFees?.error}
                        inputProps={{
                          maxLength: 6,
                        }}
                        sx={{
                          width: "100%",
                          // height: "40px",
                          fontSize: "14px",
                          "& .MuiOutlinedInput-root": {
                            fontSize: "14px",
                            "&.Mui-focused fieldset": {
                              borderColor: "#C8C7C7", // border color on focus
                            },
                          },
                        }}
                        value={formData?.consultationFees?.value || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container mb={2} alignItems="center">
                    <Grid item xs={4.5} sm={3} md={5}>
                      <FormLabel
                        htmlFor="modeOfPayment"
                        sx={{
                          fontSize: "14px",
                          fontWeight: 400,
                          whiteSpace: "nowrap",
                          color: "#000000",
                          // backgroundColor:"red"
                        }}
                      >
                        Mode of Payment
                      </FormLabel>
                    </Grid>
                    <Grid item xs={7.5} sm={9} md={7}>
                      <BasicSelect
                        name="modeOfPayment"
                        value={formData?.modeOfPayment?.value || "default"}
                        error={formData?.modeOfPayment?.error}
                        onChange={handleChange}
                      >
                        <MenuItem value="default" sx={{ display: "none" }}>
                          <span style={{ color: "#bbb7b7" }}>Select</span>
                        </MenuItem>
                        {modeOfPaymentList.map((mode, index) => (
                          <BasicMenuItem key={index} value={mode.value}>
                            {mode.label}
                          </BasicMenuItem>
                        ))}
                      </BasicSelect>
                    </Grid>
                  </Grid>
                  <Box display={"flex"} alignItems={"center"}>
                    <Button
                      sx={{
                        // mt: 3,
                        mb: 2,
                        mr: 2,
                        backgroundColor: "#D13C3C",
                        color: "#FFFFFF",
                        border: "1px solid #FFF",
                        borderRadius: "50px",
                        height: "40px",
                        "&:hover": { background: "#D13C3C" },
                        width: "154px",
                        textTransform: "none",
                      }}
                      onClick={cancelModel}
                    >
                      Cancel
                    </Button>
                    <ContainedButton
                      type="submit"
                      disabled={disabledSubmit}
                      sx={{
                        // mt: 3,
                        mb: 2,
                        borderRadius: "50px",
                        height: "40px",
                        width: "154px",
                      }}
                    >
                      Submit
                    </ContainedButton>
                  </Box>

                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={loaderopen}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Backdrop>
      </Modal>
      {openCancelModel && (
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          open={cancelModel}
        >
          <Backdrop
            open={cancelModel}
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <Paper sx={{ width: "350px", borderRadius: "10px" }}>
              <Grid sx={{ borderRadius: "15px" }} container>
                <Grid
                  item
                  xs={12}
                  sx={{ backgroundColor: "#EEF0FA", borderRadius: "10px" }}
                >
                  <Box
                    sx={{
                      height: "70px",
                      backgroundColor: "#FFD7D7",
                      borderRadius: "10px 10px 0px 0px",
                    }}
                  >
                    <IconButton
                      sx={{
                        marginTop: "35px",
                        marginLeft: "142px",
                        backgroundColor: "#D13C3C",
                        height: "54px",
                        width: "55px",
                        "&:hover": { backgroundColor: "#D13C3C" },
                        cursor: "default",
                      }}
                    >
                      <img src={cancellIcon} alt="deleteicon" />
                    </IconButton>
                  </Box>
                </Grid>
                <hr />
                <Grid item md={12} xs={12} sm={12} sx={{ padding: "0% 5% 5%" }}>
                  <Box>
                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        <Typography
                          mt={2}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          Are you sure you want
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          to cancel the activity ?
                        </Typography>
                      </Grid>
                    </Grid>

                    <Box>
                      <Box
                        sx={{ display: "flex", justifyContent: "center" }}
                        mt={2}
                      >
                        <Button
                          sx={{
                            mr: 2,
                            backgroundColor: "#000000",
                            color: "#FFFFFF",
                            height: "25px",
                            "&:hover": { background: "#000000" },
                            width: "95px",
                            fontSize: "13px",
                            fontWeight: 600,
                            borderRadius: "30px",
                            textTransform: "none",
                          }}
                          onClick={cancelModelcloseChild}
                        >
                          No
                        </Button>
                        <Button
                          sx={{
                            backgroundColor: "#41BA8F",
                            color: "#FFFFFF",
                            height: "25px",
                            "&:hover": { background: "#41BA8F" },
                            width: "95px",
                            fontSize: "13px",
                            fontWeight: 600,
                            borderRadius: "30px",
                            textTransform: "none",
                          }}
                          onClick={cancelModelclose}
                        >
                          Yes
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Backdrop>
        </Modal>
      )}
      {/* {openCancelModel && (
        <CancelAppointmentChild
          openModalCancel={cancelModel}
          closeModalCancel={cancelModelclose}
          cancelModelcloseChild={cancelModelcloseChild}
        />
      )} */}
    </>
  );
}
