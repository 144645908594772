import { MenuItem, Select, styled } from "@mui/material";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const CustomizedBasicSelect = styled(Select)({
    color: '#000',
    height: "30px",
    paddingTop: "1px",
    '&:hover:not(.Mui-disabled):not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
        borderColor: '#000',
    },
    '.MuiOutlinedInput-notchedOutline': {
        borderColor: '#C4C4C4',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #C4C4C4',
    },
});

const CustomizedMultipleSelect = styled(Select)({
    color: '#000',
    height: "30px",
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#000',
    },
    '.MuiOutlinedInput-notchedOutline': {
        borderColor: '#C4C4C4',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #C4C4C4',
    },
});

export function BasicSelect(props) {
    const { classes, children, sx, startIcon, endIcon, inputProps, readOnly, error } = props;

    return (
        <CustomizedBasicSelect
            {...props}
            fullWidth
            variant="outlined"
            sx={{ ...(sx || {}) }}
            inputProps={{
                ...(inputProps || {}),
                sx: { padding: "15px 12px", fontSize: "14px", ...(inputProps?.sx || {}) },
                readOnly: readOnly || false
            }}
            size="small"
        >{children}</CustomizedBasicSelect>
    );
}

export function MultipleSelect(props) {
    const { classes, children, sx, startIcon, endIcon, inputProps, readOnly, error, typeOfValue, typeOfKey, renderValue, value } = props;

    return (
        <CustomizedMultipleSelect
            {...props}
            multiple
            fullWidth
            variant="outlined"
            
            sx={{ ...(sx || {}) }}
            renderValue={renderValue || ((selected) => {
                if (typeOfValue == "OBJECT") {
                    return selected.map((val) => val[typeOfKey]).join(", ")
                } else {
                    return selected.join(', ')

                }
            })}
            value={value}
            inputProps={{
                ...(inputProps || {}),
                sx: { padding: "10px 12px", fontSize: "14px", height: "30px", color: value.includes("Select") && "#bbb7b7", ...(inputProps?.sx || {}) },
                readOnly: readOnly || false
            }}
            size="small"
        >{children}</CustomizedMultipleSelect>
    );
}

export function BasicMenuItem(props) {
    const { classes, children, sx, startIcon, endIcon, inputProps, readOnly, error } = props;

    return (
        <MenuItem
            {...props}
            fullWidth
            sx={{ ...(sx || {}), fontSize: { sm: "14px", xs: "12px" } }}
        >{children}</MenuItem>
    )
}