
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createTransform } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import rootReducer from '../features/counter/Reducer';
const encryptor = createTransform({
  secretKey: 'your-secret-key', 
  onError: function (error) {
    console.error('Error encrypting:', error);
  },
});
const persistConfig = {
  key: 'root',
  storage,
  transforms: [encryptTransform({ secretKey: 'your-secret-key' })],
};
// const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: rootReducer,
});
const persistor = persistStore(store);
export { store, persistor };
