  const instance = "PROD";
  let BASE_URL;
  
  if(instance == "LOCAL") {
    BASE_URL = 'http://localhost:8080'; 
  } else if(instance == "DEV") {
    BASE_URL = 'https://qglider-api-dev.azurewebsites.net';    
  } else if(instance == "UAT") {
    BASE_URL = 'https://qglider-api-uat.azurewebsites.net';
  } else if(instance == "PROD") {
    BASE_URL = 'https://api.qglider.com';
  }
   
  export default BASE_URL;