import React from "react";
import {
  Backdrop,
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Typography,
  IconButton,
} from "@mui/material";
import "../Assets/css/custom-class.css";
import timeIcon from ".././Assets/images/DeletePopup/TimeIcon.svg";

export default function TimeAdjustmentProceed({
  openModalProceed,
  closeProceedModalCancel,
  postTimeDelay,
  popupType,
  closePopUp
}) {
  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={openModalProceed}
      >
        <Backdrop
          open={openModalProceed}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <Paper sx={{ width: "350px", borderRadius: "10px" }}>
            {popupType === "SUBMIT" && (
  <Grid sx={{ borderRadius: "15px" }} container>
  <Grid
    item
    xs={12}
    sx={{ backgroundColor: "#EEF0FA", borderRadius: "10px" }}
  >
    <Box
      sx={{
        height: "70px",
        backgroundColor: "#CCDFFF",
        borderRadius: "10px 10px 0px 0px",
      }}
    >
      <IconButton
        sx={{
          marginTop: "35px",
          marginLeft: "142px",
          backgroundColor: "#2C73EB",
          height: "54px",
          width: "55px",
          "&:hover": { backgroundColor: "#2C73EB" },
          cursor:"default"
        }}
      >
        <img src={timeIcon} alt="deleteicon" />
      </IconButton>
    </Box>
  </Grid>
  <hr />
  <Grid item md={12} xs={12} sm={12} sx={{ padding: "0% 5% 5%" }}>
    <Box>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Typography
            mt={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: 600,
              fontSize: "16px",
            }}
          >
            Are you sure you want to proceed
          </Typography>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: 600,
              fontSize: "16px",
            }}
          >
            with the time delay?
          </Typography>
        </Grid>
      </Grid>

      <Box>
        <Box
          sx={{ display: "flex", justifyContent: "center" }}
          mt={2}
        >
          <Button
            sx={{
              mr: 2,
              backgroundColor: "#000000",
              color: "#FFFFFF",
              height: "25px",
              "&:hover": { background: "#000000" },
              width: "95px",
              fontSize: "13px",
              fontWeight: 600,
              borderRadius: "30px",
              textTransform: "none",
            }}
            onClick={closeProceedModalCancel}
          >
            No
          </Button>
          <Button
            onClick={()=> [postTimeDelay(),closeProceedModalCancel()]}
            sx={{
              backgroundColor: "#41BA8F",
              color: "#FFFFFF",
              height: "25px",
              "&:hover": { background: "#41BA8F" },
              width: "95px",
              fontSize: "13px",
              fontWeight: 600,
              borderRadius: "30px",
              textTransform: "none",
            }}
          >
            Yes
          </Button>
        </Box>
      </Box>
    </Box>
  </Grid>
</Grid>
            )}

            {popupType === "CANCEL" && (
                  <Grid sx={{ borderRadius: "15px" }} container>
                  <Grid
                    item
                    xs={12}
                    sx={{ backgroundColor: "#EEF0FA", borderRadius: "10px" }}
                  >
                    <Box
                      sx={{
                        height: "70px",
                        backgroundColor: "#CCDFFF",
                        borderRadius: "10px 10px 0px 0px",
                      }}
                    >
                      <IconButton
                        sx={{
                          marginTop: "35px",
                          marginLeft: "142px",
                          backgroundColor: "#2C73EB",
                          height: "54px",
                          width: "55px",
                          "&:hover": { backgroundColor: "#2C73EB" },
                          cursor:"default"
                        }}
                      >
                        <img src={timeIcon} alt="deleteicon" />
                      </IconButton>
                    </Box>
                  </Grid>
                  <hr />
                  <Grid item md={12} xs={12} sm={12} sx={{ padding: "0% 5% 5%" }}>
                    <Box>
                      <Grid container>
                        <Grid item xs={12} sm={12}>
                          <Typography
                            mt={2}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              fontWeight: 600,
                              fontSize: "16px",
                            }}
                          >
                            Are you sure you want to cancel
                          </Typography>
                          <Typography
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              fontWeight: 600,
                              fontSize: "16px",
                            }}
                          >
                            the activity?
                          </Typography>
                        </Grid>
                      </Grid>
                
                      <Box>
                        <Box
                          sx={{ display: "flex", justifyContent: "center" }}
                          mt={2}
                        >
                          <Button
                            sx={{
                              mr: 2,
                              backgroundColor: "#000000",
                              color: "#FFFFFF",
                              height: "25px",
                              "&:hover": { background: "#000000" },
                              width: "95px",
                              fontSize: "13px",
                              fontWeight: 600,
                              borderRadius: "30px",
                              textTransform: "none",
                            }}
                            onClick={closeProceedModalCancel}
                          >
                            No
                          </Button>
                          <Button
                            onClick={() => [closeProceedModalCancel(),closePopUp()]}
                            sx={{
                              backgroundColor: "#41BA8F",
                              color: "#FFFFFF",
                              height: "25px",
                              "&:hover": { background: "#41BA8F" },
                              width: "95px",
                              fontSize: "13px",
                              fontWeight: 600,
                              borderRadius: "30px",
                              textTransform: "none",
                            }}
                          >
                            Yes
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
            )}
          
          </Paper>
        </Backdrop>
      </Modal>
    </>
  );
}
