import { createSlice } from '@reduxjs/toolkit';

const data = () =>{

}
const initialState = {
        appointment:data()
}

export const bulkMoveSlice = createSlice({
    name:"appointment",
    initialState,
    reducers:{
        bulkMoveRefresh : (state,action) =>{
            state.appointment = action.payload;
        }
    }
})

export const {bulkMoveRefresh} = bulkMoveSlice.actions;
export default bulkMoveSlice.reducer