import DashboardIcon from "../Assets/navbar/dashbordIconNew.svg";
import Appointment from "../Assets/navbar/doctor-appointment.svg";
import Users from "../Assets/navbar/Manage_user.svg";
import Logout from "../Assets/navbar/logout.svg";
import Setting from "../Assets/navbar/Setting.svg"
import QRGenerate from "../Assets/navbar/qrGenerate.svg"
import dashbordHideIcon from "../Assets/icons/dashbordHideImage.svg"
import appointmentHideIcon from "../Assets/icons/appointmentHideImage.svg"
import settingHideIcon from "../Assets/icons/settingHideImage.svg"
import manageUserHideIcon from "../Assets/icons/manageuserHideImage.svg"
import qrGenImage from "../Assets/icons/qr-code.svg"
import reportsNavIcon from "../Assets/icons/reportsNavIcon.svg"





const NavItems = [
    // {
    //   name: "Dashboard",
    //   icon:DashboardIcon,  
    //   fadeIcon:dashbordHideIcon,    
    //   url : "/dashboard",
    //   key: "DASHBOARD"
    // },

    {
      name: "Manage Appointments",
      icon:Appointment,
      fadeIcon:appointmentHideIcon,
      url : "/manageappointments",
      key: "MANAGE_APPOINTMENTS",
      for: ["ALL_USER"]      
    },
    {
      name: "Manage Users",
      icon:Users,
      fadeIcon:manageUserHideIcon,
      url : "/manageusers",
      key: "MANAGE_USERS",
      activeUrl:["/manageusers", "/manageusers/addusers", "/manageusers/Editusers"],
      for: ["ALL_USER"]      
    },
    {
      name: "Settings",
      icon:Setting,
      fadeIcon:settingHideIcon,
      url : "/all-settings",
      activeUrl:["/settings"],
      key: "SETTINGS",
      for: [ "EXCEPT_USER", "EXCEPT_DOCTOR", "EXCEPT_SCAN"]
    },
    {
      name: "Settings",
      icon:Setting,
      fadeIcon:settingHideIcon,
      url : "/settings/:user_uid",
      activeUrl:["/settings"],
      key: "SETTINGS",
      for: ["USER", "DOCTOR", "SCAN"]
    },
    {
      name: "QR Code",
      icon: qrGenImage,
      fadeIcon:qrGenImage,
      url : "/generateQR",
      key: "QR_GENERATE",
      for: ["ALL_USER"]
    },
    {
      name: "Reports",
      icon: reportsNavIcon,
      fadeIcon:reportsNavIcon,
      url : "/reports",
      key: "REPORTS",
      for: ["ALL_USER"]
    },
  ];

  export default NavItems