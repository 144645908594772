import { createSlice } from '@reduxjs/toolkit';

const data = () =>{

}
const initialState = {
        appointment:data()
}

export const appointmentSlice = createSlice({
    name:"appointment",
    initialState,
    reducers:{
        appointmentReferesh : (state,action) =>{
            state.appointment = action.payload;
        }
    }
})

export const {appointmentReferesh} = appointmentSlice.actions;
export default appointmentSlice.reducer
