import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Table,
  TablePagination,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  Menu,
  MenuItem,
  Button,
  Paper,
  Tooltip,
  ClickAwayListener,
  IconButton,
  styled,
  Popover,
  useMediaQuery,
  useTheme,
  Typography,
  ListItemText,
  Checkbox,
  tableCellClasses,
} from "@mui/material";

// ------ custom import files --------
import "./reports.css";
import TableCardData from "../../../Constant/Dashboard.json";
import BioDetails from "../../ReuseComponents/Bio/BioDetails";
import BreadCrumbs from "../../ReuseComponents/BreadCrumbs/BreadCrumbs";
import Controller from "../../../Controller/ApiController";
import UtilDateTime from "../../../Constant/utillDateTime";
import { useLocation } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Header from "../../ReuseComponents/Header/Header";
import jsPDF from "jspdf";
import "jspdf-autotable";
import dayjs from "dayjs";
import Calender from "../../../Assets/dashboard/calender.svg";
import {
  ContainedButton,
  OutlinedButton,
} from "../../ReuseComponents/Button/BasicButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Doctor from "../../../Assets/dashboard/doctor.svg";
import Report from "../../../Assets/images/Report/report.svg";
import {
  BasicMenuItem,
  BasicSelect,
  MultipleSelect,
} from "../../ReuseComponents/Select/BasicSelect";
import { Alert } from "../../ReuseComponents/Alert/Alertmessage";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    maxHeight: "220px",
    marginTop: theme.spacing(1),
    width: 130,
    color: "rgb(55, 65, 81)",
    backgroundColor: "#fff",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      backgroundColor: "#fff",
    },
    "& .MuiMenuItem-root": {
      padding: "5px",
      "&:hover": {
        backgroundColor: "#fff",
      },
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
    // ...theme.applyStyles("dark", {
    //   color: theme.palette.grey[300],
    // }),
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f4f4f4",
    color: "#676666",
    border: "2px solid #D9D9D9",
  },
  [`&.${tableCellClasses.body}`]: {
    border: "2px solid #D9D9D9",
  },
  fontSize: 13,
  fontFamily: "Segoe UI",
  padding: "5px 8px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child th": {
    borderBottom: 0,
  },
}));

export default function Reports() {
  const dispatch = useDispatch();
  const currentDate = dayjs();

  const [appointmentData, setAppointmentData] = useState([]);
  const location = useLocation();
  const [loaderopen, setloaderOpen] = React.useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [reRenderFunction, setReRenderFunction] = useState(false);
  const [disableDownload, setDisableDownload] = useState(true);
  const [selectedDate, setSelectedDate] = useState({
    from: currentDate.startOf("date"),
    to: "",
    type: "",
  });
  const [customDate, setCustomDate] = useState({
    from: dayjs(new Date()).add(2, "day"), //.subtract(1, "day"),
    to: dayjs(new Date()).add(3, "day"),
  });
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [users, setUsers] = useState([]);
  const [reportType, setReportType] = useState("");
  const [reportError, setReportError] = useState("");
  const [generated, setGenerated] = useState(false);

  // Set it to the start of the day
  const filterData = useSelector((state) => state.filterData.data);
  const userDetails = useSelector((state) => state.userDetails.data);

  const handleLoaderClose = () => {
    setloaderOpen(false);
  };
  const handleLoaderOpen = () => {
    setloaderOpen(true);
  };

  const handleDownloadClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSendEmail = async () => {
    try {
      handleLoaderOpen();
      let queryParams = {
        "filter.report_type": reportType,
        "filter.doctor_uid": userDetails.admin_uid || "",
        "filter.user_uid":
          selectedDoctors.map((val) => val.user_uid).join(",") || "",
      };

      if (selectedDate.from) {
        queryParams = {
          ...queryParams,
          "filter.from": selectedDate.from.toISOString(),
        };
      }
      if (selectedDate.to) {
        queryParams = {
          ...queryParams,
          "filter.to": selectedDate.to.toISOString(),
        };
      }

      const response = await Controller.ApiController(
        "GET",
        "/report_mail",
        "",
        queryParams
      );
      handleLoaderClose();

      if (response.type == "success") {
        Alert("success", response.message);
      } else {
        Alert("error", response?.error?.message || "Something went wrong");
      }
    } catch (e) {
      handleLoaderClose();
      console.log("User Request: ", e.message);
    }
  };

  async function fetchGetAPI() {
    handleLoaderOpen();
    let docteruid = localStorage.getItem("DoctorUid");

    let data = {
      "filter.doctor_uid": docteruid,
      page: page + 1,
      count: rowsPerPage,
      "filter.report_type": reportType,
    };

    if (selectedDate.from) {
      data = {
        ...data,
        "filter.from_time": selectedDate.from.toISOString(),
      };
    }
    if (selectedDate.to) {
      data = {
        ...data,
        "filter.to_time": selectedDate.to.toISOString(),
      };
    }

    if (userDetails.user_uid) {
      data = { ...data, "filter.user_uid": userDetails.user_uid };
    } else if (selectedDoctors.length > 0) {
      data = {
        ...data,
        "filter.user_uid": selectedDoctors.map((val) => val.user_uid).join(","),
      };
    } else if (
      userDetails.user_uid &&
      !["SCAN", "DOCTOR"].includes(userDetails.user_type)
    ) {
      data = { ...data, "filter.user_uid": userDetails.user_uid };
    }

    let response = await Controller.ApiController(
      "GET",
      "/appointment",
      "",
      data
    );
    handleLoaderClose();
    if (response.type == "success") {
      setAppointmentData(response);
      if (Array.isArray(response.data) && response.data.length > 0) {
        setDisableDownload(false);
      } else {
        setDisableDownload(true);
      }
    }
  }

  const generateData = () => {
    if (reportType != "") {
      fetchGetAPI();
    } else {
      setReportError(true);
    }
  };

  useEffect(() => {
    if (reRenderFunction) {
      startOfThePage();
    }
  }, []);

  useEffect(() => {
    if (userDetails && !!reportType) {
      fetchGetAPI();
    }
  }, [page, rowsPerPage, userDetails]);

  const formattedDateTime = (date) => {
    return new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",

      // hour: 'numeric',
      // minute: 'numeric',
      // hour12: true,
    }).format(date);
  };

  const convertToTitleCase = (inputString) => {
    const words = inputString.toLowerCase().split("_");

    const titleCaseWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    const titleCaseString = titleCaseWords.join(" ");

    return titleCaseString;
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [dateAnchorEl, setDateAnchorEl] = useState(null);
  const dateOpen = Boolean(dateAnchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, value) => {
    addURLQueryParam(value, rowsPerPage);
    setPage(value);
  };

  const handleMenuClose = () => {
    // Close the menu only if the popover is not open
    if (!popoverAnchor) {
      setDateAnchorEl(null);
    }
  };

  const userRequest = async () => {
    try {
      const queryParams = {
        "filter.user_type": "DOCTOR,SCAN",
        "filter.doctor_uid": userDetails.admin_uid || "",
        "filter.user_uid": userDetails?.user_uid || "",
        "filter.user_uid_type": "MAPED_DOCTOR",
      };

      const response = await Controller.ApiController(
        "GET",
        "/manage_users",
        "",
        queryParams
      );
      if (response.type == "success") {
        setUsers(response?.data || []);
      }
    } catch (e) {
      console.log("User Request: ", e.message);
    }
  };

  useEffect(() => {
    if (userDetails.filter) {
      userRequest();
    }
  }, [userDetails]);

  useEffect(() => {
    setReRenderFunction(true);
    const currentUrl = new URL(window.location.href);
    let pageNumber = currentUrl.searchParams.get("page");
    let perPage = currentUrl.searchParams.get("perPage");
    pageNumber = parseInt(pageNumber) || 0;
    perPage = parseInt(perPage) || 0;
    if (pageNumber > 0 || perPage > 4) {
      // fetchGetAPI(pageNumber, perPage);
      setPage(pageNumber);
      setRowsPerPage(perPage);
    } else {
      startOfThePage();
    }
  }, []);

  const startOfThePage = () => {
    addURLQueryParam(0, 100);
    // fetchGetAPI(0, 15);
    setPage(0);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    addURLQueryParam(0, parseInt(event.target.value));
    setPage(0);
    fetchGetAPI(0, event.target.value);
  };

  const addURLQueryParam = (page, rowsPerPage) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", page);
    url.searchParams.set("perPage", rowsPerPage);
    window.history.replaceState({}, "", url);
  };

  const convertconsultationType = (inputString) => {
    return inputString == "NORMAL"
      ? "New"
      : inputString == "EMERGENCY"
      ? "EMG"
      : inputString == "REVIEW_APPOINTMENT"
      ? "Review Appointment"
      : "";
  };

  const scanTypeConvert = (source) => {
    return source;
    if (!source) {
      return "-";
    }
    let value = source.split(" ");
    value = value
      .map((val) => val.charAt(0).toUpperCase() + val.slice(1).toLowerCase())
      .join(" ");
    return value;
  };

  const bookingSourceConvert = (source) => {
    if (!source) {
      return "-";
    }
    const value = source.split("_")[0];

    if (["QR", "IVR"].includes(value)) {
      return value;
    } else {
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }
  };

  const convertCase = (source) => {
    if (!source) {
      return "-";
    }
    const value = source
      .split("_")
      .map(
        (value) => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
      )
      .join(" ");
    return value;
  };

  async function downloadFetchGetAPI() {
    handleLoaderOpen();
    try {
      let docteruid = localStorage.getItem("DoctorUid");

      let data = {
        "filter.doctor_uid": docteruid,
        "filter.report_type": reportType,
        "filter.paginate": "NO",
      };

      if (selectedDate.from) {
        data = {
          ...data,
          "filter.from_time": selectedDate.from.toISOString(),
        };
      }
      if (selectedDate.to) {
        data = {
          ...data,
          "filter.to_time": selectedDate.to.toISOString(),
        };
      }

      if (userDetails.user_uid) {
        data = { ...data, "filter.user_uid": userDetails.user_uid };
      } else if (selectedDoctors.length > 0) {
        data = {
          ...data,
          "filter.user_uid": selectedDoctors
            .map((val) => val.user_uid)
            .join(","),
        };
      } else if (
        userDetails.user_uid &&
        !["SCAN", "DOCTOR"].includes(userDetails.user_type)
      ) {
        data = { ...data, "filter.user_uid": userDetails.user_uid };
      }

      let response = await Controller.ApiController(
        "GET",
        "/appointment",
        "",
        data
      );
      handleLoaderClose();
      if (response.type == "success") {
        if (Array.isArray(response.data) && response.data.length > 0) {
          return response;
        } else {
          return [];
        }
      }
      return [];
    } catch (e) {
      console.log("Download CSV Error: ", e.message);
      return [];
    }
  }

  const convertAmount = (value) => {
    if (!value || parseInt(value) == "NaN") {
      return 0;
    }
    return parseInt(value).toLocaleString();
  };

  const countReportValues = (value, type) => {
    let totalCash = 0;
    let totalUPI = 0;
    let totalCard = 0;
    let totalUPIAndCash = 0;
    let returnValue = 0;
    if (type == "VACCINE") {
      totalCash = parseInt(value?.vaccine_total_cash || 0);
      totalUPI = parseInt(value?.vaccine_total_upi || 0);
      totalCard = parseInt(value?.vaccine_total_card || 0);
      totalUPIAndCash = parseInt(value?.vaccine_total_upi_and_cash || 0);
      returnValue = totalCash + totalUPI + totalCard + totalUPIAndCash;
    } else if (type == "CONSULTATION") {
      totalCash = parseInt(value?.consultation_total_cash || 0);
      totalUPI = parseInt(value?.consultation_total_upi || 0);
      totalCard = parseInt(value?.consultation_total_card || 0);
      totalUPIAndCash = parseInt(value?.consultation_total_upi_and_cash || 0);
      returnValue = totalCash + totalUPI + totalCard + totalUPIAndCash;
    } else {
      totalCash =
        parseInt(value?.vaccine_total_cash || 0) +
        parseInt(value?.consultation_total_cash || 0);
      totalUPI =
        parseInt(value?.vaccine_total_upi || 0) +
        parseInt(value?.consultation_total_upi || 0);
      totalCard =
        parseInt(value?.vaccine_total_card || 0) +
        parseInt(value?.consultation_total_card || 0);
      totalUPIAndCash =
        parseInt(value?.vaccine_total_upi_and_cash || 0) +
        parseInt(value?.consultation_total_upi_and_cash || 0);
      returnValue = totalCash + totalUPI + totalCard + totalUPIAndCash;
    }
    returnValue = convertAmount(returnValue);
    return returnValue + ".00";
  };

  const downloadCSV = async () => {
    const csvRows = [];
    const csvData = await downloadFetchGetAPI();

    const paymentReport = csvData.payment_report;
    if (reportType == "PAYMENT_REPORT") {
      const summaryHeader = TableCardData.totalCashReport.map(
        (header) => header.TblHead
      );
      summaryHeader.push("Total (A + B)");
      csvRows.push(summaryHeader.join(","));

      const summaryValues = [
        convertAmount(paymentReport?.vaccine_total_cash) + ".00",
        convertAmount(paymentReport?.vaccine_total_upi) + ".00",
        convertAmount(paymentReport?.vaccine_total_card) + ".00",
        convertAmount(paymentReport?.vaccine_total_upi_and_cash) + ".00",
        countReportValues(paymentReport, "VACCINE"),
        convertAmount(paymentReport?.consultation_total_cash) + ".00",
        convertAmount(paymentReport?.consultation_total_upi) + ".00",
        convertAmount(paymentReport?.consultation_total_card) + ".00",
        convertAmount(paymentReport?.consultation_total_upi_and_cash) + ".00",
        countReportValues(paymentReport, "CONSULTATION"),
        countReportValues(paymentReport, "TOTAL"),
      ];
      csvRows.push(summaryValues.join(","));
      csvRows.push([]);
    }

    const headers = TableCardData.tableRowHead4
      .filter((header) => header.type == reportType || header.type == "ALL")
      .map((header) => header.TblHead);
    csvRows.push(headers.join(","));

    csvData.data.forEach((RowCellData, index) => {
      const values = [
        index + 1, // Index calculation
        UtilDateTime.formatYearMonthAndDayAsFullString(
          RowCellData?.from_time || "-"
        ), // Date
        ...(reportType == "CONSULTATION_REPORT"
          ? [UtilDateTime.formatTimeAsHHMMTT(RowCellData?.from_time || "-")]
          : []),
        RowCellData?.token || "Null", // Token
        RowCellData?.patient_name || "Null", // Patient Name
        RowCellData?.mobile_number || "Null", // Mobile Number
        ...(reportType == "CONSULTATION_REPORT"
          ? [bookingSourceConvert(RowCellData?.booking_source) || "Null"]
          : []), // Booking Source
        convertToTitleCase(RowCellData?.booking_status) || "Null", // Booking Status
        ...(reportType == "CONSULTATION_REPORT"
          ? [
              RowCellData?.referred_doctor || "Null",
              scanTypeConvert(RowCellData?.scan_type) || "Null",
              RowCellData?.in_que_time
                ? UtilDateTime.formatTimeAsHHMMTT(RowCellData.in_que_time)
                : "Null",
              RowCellData?.check_in_time
                ? UtilDateTime.formatTimeAsHHMMTT(RowCellData.check_in_time)
                : "Null",
              RowCellData?.check_out_time
                ? UtilDateTime.formatTimeAsHHMMTT(RowCellData.check_out_time)
                : "Null",
            ]
          : []),
        ...(reportType == "PAYMENT_REPORT"
          ? [
              convertAmount(RowCellData?.vaccine_fee) + ".00",
              convertAmount(RowCellData?.consultation_fee) + ".00",
            ]
          : []),
      ];
      csvRows.push(values.join(","));
    });

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;

    let fromDate = dayjs(selectedDate?.from).format("DD-MMM-YYYY");
    let toDate = selectedDate?.to
      ? dayjs(selectedDate?.to).format("DD-MMM-YYYY")
      : "";

    if (toDate && fromDate != toDate) {
      toDate = " - " + toDate;
    } else {
      toDate = "";
    }

    a.download = `${convertCase(reportType)} - ${fromDate}${toDate}.csv`;
    a.click();
    handleClose();
  };

  const downloadPDF = async (value) => {
    const pdfData = await downloadFetchGetAPI();
    const paymentReport = pdfData.payment_report;

    const landscape = value == "landscape";
    const doc = new jsPDF({
      orientation: value,
      unit: "mm",
      format: "a4",
    });

    const logo = require("../../../Assets/qgliderlogo.png");
    doc.addImage(logo, "PNG", 14, 10, 45, 16);

    // Add a title
    doc.setFontSize(16);
    doc.text("Consultation Reports", 14, 40);

    let fromDate = dayjs(selectedDate?.from).format("DD-MMM-YYYY");
    let toDate = selectedDate?.to
      ? dayjs(selectedDate?.to).format("DD-MMM-YYYY")
      : "";

    // Add a sub-content
    doc.setFontSize(10);
    if (fromDate) {
      if (landscape) {
        doc.text(`From Date: ${fromDate}`, 205, 40);
      } else {
        doc.text(`From Date: ${fromDate}`, 115, 40);
      }
    }
    if (toDate.length > 0) {
      if (landscape) {
        doc.text(`To Date: ${toDate}`, 247, 40);
      } else {
        doc.text(`To Date: ${toDate}`, 160, 40);
      }
    }

    reportType == "PAYMENT_REPORT" &&
      doc.autoTable({
        startY: 45,
        head: [
          [
            {
              content: "Vaccine",
              colSpan: 5,
              styles: { halign: "center", cellPadding: { top: 1, bottom: 1 } },
            },
            {
              content: "Consultation",
              colSpan: 5,
              styles: { halign: "center", cellPadding: { top: 1, bottom: 1 } },
            },
            {
              content: `Total\nA + B`,
              rowSpan: 2,
              styles: { halign: "center", cellPadding: { top: 2, bottom: 1 } },
            },
          ],
          TableCardData.totalCashReport.map((header) => ({
            content: `${header.TblHead}`,
            styles: { halign: "center", cellPadding: { top: 1, bottom: 1 } },
          })),
        ],
        body: [
          [
            convertAmount(paymentReport?.vaccine_total_cash) + ".00",
            convertAmount(paymentReport?.vaccine_total_upi) + ".00",
            convertAmount(paymentReport?.vaccine_total_card) + ".00",
            convertAmount(paymentReport?.vaccine_total_upi_and_cash) + ".00",
            countReportValues(paymentReport, "VACCINE"),
            convertAmount(paymentReport?.consultation_total_cash) + ".00",
            convertAmount(paymentReport?.consultation_total_upi) + ".00",
            convertAmount(paymentReport?.consultation_total_card) + ".00",
            convertAmount(paymentReport?.consultation_total_upi_and_cash) +
              ".00",
            countReportValues(paymentReport, "CONSULTATION"),
            countReportValues(paymentReport, "TOTAL"),
          ],
        ],
        theme: "grid",

        headStyles: {
          lineWidth: 0.1,
          lineColor: [209, 205, 205],
          halign: "center",
          fillColor: [65, 186, 143],
          textColor: [255, 255, 255],
          fontSize: landscape ? 8 : 7,
          fontStyle: "none",
        },

        columnStyles: {
          0: { cellWidth: 15 }, // Vaccine Total Cash
          1: { cellWidth: 15 }, // Vaccine Total UPI
          2: { cellWidth: 15 }, // Vaccine Total Card
          3: { cellWidth: 22 }, // Vaccine Total Cash and UPI
          4: { cellWidth: 18 }, // Total Vaccine
          5: { cellWidth: 15 }, // Consultation Total Cash
          6: { cellWidth: 15 }, // Consultation Total UPI
          7: { cellWidth: 15 }, // Consultation Total Card
          8: { cellWidth: 22 }, // Consultation Total Cash and UPI
          9: { cellWidth: 17 }, // Total Consultation
          10: { cellWidth: 16 }, // Total Payment
        },

        styles: {
          lineWidth: 0.1,
          lineColor: [209, 205, 205],
          cellPadding: { top: 2, right: 2, bottom: 2, left: 1 },
          fontSize: landscape ? 8 : 7,
          overflow: "linebreak",
          halign: "center",
        },
      });
    const parseBody = pdfData.data.map((row, index) =>
      [
        index + 1,
        UtilDateTime.formatYearMonthAndDayAsInt(row?.from_time || "-"),
        reportType == "CONSULTATION_REPORT" &&
          UtilDateTime.formatTimeAsHHMMTT(row?.from_time || "-"),
        row?.token || "-",
        row?.patient_name || "-",
        row?.mobile_number || "-",
        reportType == "CONSULTATION_REPORT" &&
          (bookingSourceConvert(row?.booking_source) || "-"),
        convertToTitleCase(row?.booking_status) || "-",
        ...(reportType == "CONSULTATION_REPORT"
          ? [
              scanTypeConvert(row?.scan_type) || "-",
              row?.referred_doctor || "-",
              row?.in_que_time
                ? UtilDateTime.formatTimeAsHHMMTT(row.in_que_time)
                : "-",
              row?.check_in_time
                ? UtilDateTime.formatTimeAsHHMMTT(row?.check_in_time)
                : "-",
              row?.check_out_time
                ? UtilDateTime.formatTimeAsHHMMTT(row?.check_out_time)
                : "-",
            ]
          : []),
        reportType == "PAYMENT_REPORT" &&
          convertAmount(row?.vaccine_fee) + ".00",
        reportType == "PAYMENT_REPORT" &&
          convertAmount(row?.consultation_fee) + ".00",
      ].filter((val) => val)
    );

    // Add table to PDF
    doc.autoTable({
      startY: reportType == "PAYMENT_REPORT" ? 65 : 45,
      head: [
        TableCardData.tableRowHead4
          .filter((header) => reportType == header.type || header.type == "ALL")
          .map((header) => header.TblHead),
      ],
      body: parseBody,
      theme: "grid",

      headStyles: {
        lineWidth: 0.1,
        lineColor: [209, 205, 205],
        fillColor: [65, 186, 143],
        textColor: [255, 255, 255],
        fontSize: landscape ? 8 : 7,
        fontStyle: "none",
        cellPadding: 1,
      },

      columnStyles:
        reportType == "CONSULTATION_REPORT"
          ? {
              0: { cellWidth: 9 }, // S.No.
              1: { cellWidth: 16 }, // Appointment Date
              2: { cellWidth: 14 }, // Appointment Time
              3: { cellWidth: 11 }, // Token#
              4: { cellWidth: 15 }, // Patient Name
              5: { cellWidth: 17 }, // Mobile Number
              6: { cellWidth: 12 }, // Booking Source
              7: { cellWidth: 15 }, // Booking Status
              8: { cellWidth: 16 }, // Scan Type
              9: { cellWidth: 14 }, // Referred By
              10: { cellWidth: 15 }, // Arrival Time
              11: { cellWidth: 14 }, // Check-In Time
              12: { cellWidth: 17 }, // Check-Out Time
            }
          : {
              0: { cellWidth: 10 }, // S.No.
              1: { cellWidth: 25 }, // Appointment Date
              2: { cellWidth: 20 }, // Token#
              3: { cellWidth: 35 }, // Patient Name
              4: { cellWidth: 20 }, // Mobile Number
              5: { cellWidth: 25 }, // Booking Status
              6: { cellWidth: 25 }, // Vaccine Fee
              7: { cellWidth: 25 }, // Consultation Fee
            },
      styles: {
        cellPadding: { top: 2, right: 1, bottom: 2, left: 1 },
        fontSize: landscape ? 8 : 7,
        overflow: "linebreak",
      },
    });

    // Add total cost
    // doc.text(`Total Cost (AED): ${totalCost}`, 14, doc.lastAutoTable.finalY + 10);

    if (toDate && fromDate != toDate) {
      toDate = " - " + toDate;
    } else {
      toDate = "";
    }

    // Save the PDF
    doc.save(`${convertCase(reportType)} - ${fromDate}${toDate}`); //${dayjs(new Date()).format("DD-MMM-YYYY")}.pdf`);
    handleClose();
  };

  const capitalize = (str) => {
    if (!str) return "-"; // Return default if no string
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const handleTimeChange = (field, time) => {
    setCustomDate({ ...customDate, [field]: time });
    // Reset "To" time if "From" time changes to ensure consistency
    if (field === "from") {
      let toTime = dayjs(customDate.to);
      if (time.isAfter(toTime)) {
        setCustomDate((prev) => ({ ...prev, to: time }));
      }
    }

    if (field === "to") {
      let fromTime = dayjs(customDate.from);
      if (time.isBefore(fromTime)) {
        setCustomDate((prev) => ({ ...prev, from: time }));
      }
    }
  };

  const handleClickAway = (event) => {
    setDateAnchorEl(null);
    setPopoverAnchor(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDateMenuOpen = (event) => {
    setDateAnchorEl(event.currentTarget);
  };

  const handleDatechange = (key) => {
    let filterFrom = currentDate.startOf("date");
    let filterTo = currentDate.endOf("date");
    let type = "";
    if (key == "TODAY") {
      setDateAnchorEl(null);
      setPopoverAnchor(null);
      type = "SINGLE";
    } else if (key == "TOMORROW") {
      filterFrom = dayjs(currentDate.add(1, "days")).startOf("date");
      filterTo = dayjs(currentDate.add(1, "days")).endOf("date");
      setDateAnchorEl(null);
      setPopoverAnchor(null);
      type = "SINGLE";
    } else if (key == "CUSTOM") {
      filterFrom = dayjs(customDate.from).startOf("date");
      filterTo = dayjs(customDate.to).endOf("date");
      setDateAnchorEl(null);
      setPopoverAnchor(null);
      type = "MULTI";
      if (filterFrom.format("DD-MM-YYYY") == filterTo.format("DD-MM-YYYY")) {
        type = "SINGLE";
      }
    } else if (key == "CLEAR") {
      setDateAnchorEl(null);
      setPopoverAnchor(null);
      filterFrom = filterFrom;
      filterTo = "";
      type = "";
    }
    setSelectedDate({
      from: filterFrom,
      to: filterTo,
      type: type,
    });
    handleClose();
    if (appointmentData) {
      setAppointmentData({});
    }
  };

  const handleCustomHover = (event) => {
    setPopoverAnchor(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchor(null);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // for small screens

  const getAnchorOrigin = () => {
    if (isSmallScreen) {
      return {
        vertical: "bottom",
        horizontal: "center",
      };
    } else {
      return {
        vertical: "center",
        horizontal: "right",
      };
    }
  };

  const getTransformOrigin = () => {
    if (isSmallScreen) {
      return;
    } else {
      return {
        vertical: "center",
        horizontal: "left",
      };
    }
  };

  const handlechange = (e) => {
    const { value, name } = e.target;
    let filerValue = typeof value === "string" ? value.split(",") : value;
    filerValue = filerValue.filter((val) => val != "Select");
    setSelectedDoctors(filerValue);
    if (appointmentData) {
      setAppointmentData({});
    }
  };

  const handleReportChange = (e) => {
    setReportType(e.target.value);
    if (reportError) {
      setReportError(false);
    }
    if (appointmentData) {
      setAppointmentData({});
    }
    setDisableDownload(true);
  };

  return (
    <>
      <Grid container spacing={0} display={"flex"} alignItems={"center"}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ display: { xs: "", sm: "none" } }}
        >
          <Header />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ marginTop: { xs: "0px", sm: "0px" }, marginBottom: "25px" }}
        >
          <BreadCrumbs
            crumbs={["Dashboard", "Reports"]}
            paths={["/dashboard", "/reports"]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box>
            <BioDetails />
          </Box>
          <Box
            sx={{
              padding: "15px 0px",
              display: {
                xs: "block",
                sm: "block",
                md: "none",
                lg: "none",
                xl: "none",
              },
            }}
          >
            <h3>Reports</h3>
          </Box>
        </Grid>
      </Grid>
      <Grid container mt={3}>
        <Grid item xs={12} sx={{ display: { lg: "flex" } }}>
          <div
            className="flx"
            style={{
              maxWidth: "350px",
              marginRight: "30px",
              marginTop: "10px",
            }}
          >
            <img src={Report} sx={{ height: "30px" }} alt={"Calender.logo"} />
            <BasicSelect
              sx={{ width: "180px", ml: 1, fontSize: "14px" }}
              value={reportType || "default"}
              error={reportError}
              onChange={handleReportChange}
            >
              <MenuItem value="default" style={{ display: "none" }}>
                <span style={{ color: "#bbb7b7" }}>Select</span>
              </MenuItem>
              <BasicMenuItem value="CONSULTATION_REPORT">
                Consultation Report
              </BasicMenuItem>
              <BasicMenuItem value="PAYMENT_REPORT">
                Payment Report
              </BasicMenuItem>
            </BasicSelect>
          </div>

          <div
            className="flx"
            style={{
              whiteSpace: "nowrap",
              marginRight: "30px",
              marginTop: "10px",
            }}
          >
            <img
              src={Calender}
              // sx={{ height: { sm: "30px", xs: "30px",  } }}
              alt={"Calender.logo"}
            />

            <ClickAwayListener onClickAway={handleClickAway}>
              <div>
                <OutlinedButton
                  id="demo-customized-button"
                  disableElevation
                  onClick={handleDateMenuOpen}
                  endIcon={<ArrowDropDownIcon />}
                  sx={{
                    fontSize: "14px",
                    ml: 1,
                    height: "30px",
                    textTransform: "none",
                    fontWeight: 400,
                    minWidth: "180px",
                    "-webkit-justify-content": "left",
                    padding: "0px 25px 0px 12px",
                    "& .MuiButton-endIcon": {
                      position: "absolute",
                      right: 0,
                      marginRight: "6px",
                    },
                  }}
                >
                  {selectedDate?.from && selectedDate?.to ? (
                    <span style={{ fontWeight: 400 }}>
                      {selectedDate?.from
                        ? selectedDate?.from.format("DD-MMM-YYYY")
                        : ""}
                      {selectedDate?.to && selectedDate?.type == "MULTI"
                        ? " - " + selectedDate?.to.format("DD-MMM-YYYY")
                        : ""}
                    </span>
                  ) : (
                    <span style={{ color: "#bbb7b7", fontWeight: 400 }}>
                      Select
                    </span>
                  )}
                </OutlinedButton>
                {selectedDate.from && selectedDate.to && (
                  <Tooltip title="Clear" placement="top">
                    <IconButton
                      onClick={() => handleDatechange("CLEAR")}
                      sx={{ padding: 0, ml: 0.5 }}
                    >
                      <HighlightOffIcon sx={{ color: "#BC123E" }} />
                    </IconButton>
                  </Tooltip>
                )}
                <StyledMenu
                  anchorEl={dateAnchorEl}
                  open={Boolean(dateAnchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem
                    onClick={() => handleDatechange("TODAY")}
                    sx={{ fontSize: "14px" }}
                  >
                    Today
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleDatechange("TOMORROW")}
                    sx={{ fontSize: "14px" }}
                  >
                    Tomorrow
                  </MenuItem>
                  <MenuItem
                    disableRipple
                    onClick={handleCustomHover}
                    sx={{ fontSize: "14px" }}
                  >
                    Custom
                  </MenuItem>
                </StyledMenu>

                <Popover
                  open={Boolean(popoverAnchor)}
                  anchorEl={popoverAnchor}
                  onClose={handlePopoverClose}
                  anchorOrigin={getAnchorOrigin()}
                  transformOrigin={getTransformOrigin()}
                  disableEnforceFocus
                  disableAutoFocus
                  disableRestoreFocus
                  sx={{
                    "& .MuiPopover-paper": {
                      overflow: "hidden",
                      padding: "0px 10px",
                    },
                  }}
                >
                  <Box sx={{}}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={"en-gb"}
                    >
                      <DesktopDatePicker
                        format="DD-MM-YYYY"
                        slotProps={{
                          textField: {
                            sx: {
                              "& .MuiOutlinedInput-root": {
                                width: "135px",
                                height: "30px",
                                padding: 0,
                                margin: "5px 10px 5px 0px",
                                fontSize: "14px",
                                paddingRight: "0px",
                                "& fieldset": {
                                  borderColor: "#C4C4C4",
                                },
                                color: "#000",
                                fontFamily: "Segoe UI",
                                "&:hover fieldset": {
                                  borderColor: "#000",
                                },
                                "&.Mui-focused fieldset": {
                                  border: "1px solid #C4C4C4",
                                },
                              },
                              ".MuiIconButton-root": {
                                marginRight: "8px",
                                padding: "0px",
                              },
                            },
                            inputProps: { readOnly: true },
                          },
                        }}
                        // shouldDisableDate={disableDatesFrom}
                        value={customDate.from || dayjs(new Date())}
                        onChange={(newValue) =>
                          handleTimeChange("from", newValue)
                        }
                      />

                      <DesktopDatePicker
                        format="DD-MM-YYYY"
                        slotProps={{
                          textField: {
                            sx: {
                              "& .MuiOutlinedInput-root": {
                                width: "135px",
                                height: "30px",
                                padding: 0,
                                paddingRight: "0px",
                                fontSize: "14px",
                                margin: "5px 10px 5px 0px",
                                "& fieldset": {
                                  borderColor: "#C4C4C4",
                                },
                                color: "#000",
                                fontFamily: "Segoe UI",
                                "&:hover fieldset": {
                                  borderColor: "#000",
                                },
                                "&.Mui-focused fieldset": {
                                  border: "1px solid #C4C4C4",
                                },
                              },
                              ".MuiIconButton-root": {
                                marginRight: "8px",
                                padding: "0px",
                              },
                            },
                            inputProps: { readOnly: true },
                          },
                        }}
                        // shouldDisableDate={disableDatesTo}
                        value={customDate.to || dayjs(new Date())}
                        onChange={(newValue) =>
                          handleTimeChange("to", newValue)
                        }
                      />
                    </LocalizationProvider>
                    <ContainedButton
                      onClick={() => handleDatechange("CUSTOM")}
                      sx={{
                        margin: "5px 0px 5px 0px",
                      }}
                    >
                      Apply
                    </ContainedButton>
                  </Box>
                </Popover>
              </div>
            </ClickAwayListener>
          </div>

          <div
            className="flx"
            style={{
              maxWidth: "350px",
              minWidth: "150px",
              marginRight: "20px",
              marginTop: "10px",
            }}
          >
            <img src={Doctor} sx={{ height: "30px" }} alt={"Calender.logo"} />
            {(userDetails.user_type == "DOCTOR" ||
            userDetails.user_type == "SCAN") ? (
              <Typography
                className="center-image-txt"
                sx={{ fontSize: { sm: "14px", xs: "12px" }, ml: 2, whiteSpace: "wrap", wordBreak: "break-word" }}
              >
                {userDetails.user_full_name || "--"}
              </Typography>
            ) : (
              <MultipleSelect
                sx={{ width: "180px", ml: 1 }}
                value={
                  selectedDoctors.length > 0 ? selectedDoctors : ["Select"]
                }
                onChange={handlechange}
                typeOfValue={selectedDoctors.length > 0 && "OBJECT"}
                typeOfKey="full_name"
              >
                <MenuItem value="Select" style={{ display: "none" }}>
                  Select
                </MenuItem>
                {Array.isArray(users) && users.length > 0 ? (
                  users.map((val, i) => (
                    <BasicMenuItem key={i} value={val}>
                      <ListItemText
                        primary={val.full_name}
                        sx={{
                          "& .MuiListItemText-primary": {
                            fontSize: { sm: "14px", xs: "12px" },
                          },
                        }}
                      />
                      <Checkbox
                        sx={{
                          padding: 0,
                          marginLeft: "10px",
                          "&.Mui-checked": { color: "#243665" },
                        }}
                        checked={selectedDoctors.includes(val)}
                        size="small"
                      />
                    </BasicMenuItem>
                  ))
                ) : (
                  <BasicMenuItem disabled>No Doctors Available</BasicMenuItem>
                )}
              </MultipleSelect>
            )}
          </div>
          <div>
            <ContainedButton
              sx={{ width: "90px", marginTop: "1px", marginTop: "10px" }}
              onClick={generateData}
            >
              Generate
            </ContainedButton>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box
            sx={{ textAlign: "right", mb: 1, mt: 1 }}
            item
            xs={12}
            md={12}
            lg={12}
            xl={12}
          >
            <Tooltip
              title={disableDownload && "Nothing to send Email"}
              placement="top"
            >
              <span>
                <Button
                  sx={{
                    backgroundColor: disableDownload ? "#E6E6E6" : "#243665",
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    color: disableDownload ? "none" : "#fff !important",
                    textTransform: "none",
                    borderRadius: "50px",
                    padding: "3px 20px",
                    "&:hover": {
                      backgroundColor: !disableDownload && "#243665",
                    },
                    marginRight: "20px",
                  }}
                  disabled={disableDownload}
                  onClick={handleSendEmail}
                >
                  Send Email
                </Button>
              </span>
            </Tooltip>
            <Tooltip
              title={disableDownload && "Nothing to Generate the Report"}
              placement="top"
            >
              <span>
                <Button
                  sx={{
                    backgroundColor: disableDownload ? "#E6E6E6" : "#243665",
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    color: disableDownload ? "none" : "#fff !important",
                    textTransform: "none",
                    borderRadius: "50px",
                    padding: "3px 20px",
                    "&:hover": {
                      backgroundColor: !disableDownload && "#243665",
                    },
                  }}
                  disabled={disableDownload}
                  onClick={handleDownloadClick}
                >
                  Download
                </Button>
              </span>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "download-button",
              }}
            >
              <BasicMenuItem onClick={downloadCSV}>Download CSV</BasicMenuItem>
              <BasicMenuItem onClick={() => downloadPDF("portrait")}>
                Download PDF
              </BasicMenuItem>
              {/* <MenuItem onClick={() => downloadPDF("landscape")}>Download Landscape PDF</MenuItem> */}
            </Menu>
          </Box>
        </Grid>
        {reportType == "PAYMENT_REPORT" && (
          <Grid
            item
            xs={12}
            mb={2}
            sx={{ width: { xs: "92vw", sm: "94vw", md: "60vw" } }}
          >
            <TableContainer>
              <Table
                // stickyHeader
                sx={{
                  borderCollapse: "collapse",
                  width: "100%",
                  overflowY: "auto",
                }}
              >
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell sx={{ textAlign: "center" }} colSpan={5}>
                      Vaccine
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }} colSpan={5}>
                      Consultation
                    </StyledTableCell>
                    <StyledTableCell
                      rowSpan={2}
                      sx={{
                        backgroundColor: "#B6D7A8 !important",
                        textAlign: "center",
                      }}
                    >
                      &#8377;Total <br /> (A + B)
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    {TableCardData.totalCashReport.map((rowHead, index) => (
                      <StyledTableCell
                        key={index}
                        sx={{
                          backgroundColor: rowHead?.backgroudColor
                            ? `${rowHead.backgroudColor} !important`
                            : "",
                          textAlign: "center",
                        }}
                      >
                        &#8377;{rowHead.TblHead}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow hover>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      {convertAmount(
                        appointmentData?.payment_report?.vaccine_total_cash
                      )}
                      .00
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      {convertAmount(
                        appointmentData?.payment_report?.vaccine_total_upi
                      )}
                      .00
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      {convertAmount(
                        appointmentData?.payment_report?.vaccine_total_card
                      )}
                      .00
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      {convertAmount(
                        appointmentData?.payment_report
                          ?.vaccine_total_upi_and_cash
                      )}
                      .00
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        backgroundColor: "#D9EAD3 !important",
                        textAlign: "center",
                      }}
                    >
                      {countReportValues(
                        appointmentData?.payment_report,
                        "VACCINE"
                      )}
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      {convertAmount(
                        appointmentData?.payment_report?.consultation_total_cash
                      )}
                      .00
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      {convertAmount(
                        appointmentData?.payment_report?.consultation_total_upi
                      )}
                      .00
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      {convertAmount(
                        appointmentData?.payment_report?.consultation_total_card
                      )}
                      .00
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      {convertAmount(
                        appointmentData?.payment_report
                          ?.consultation_total_upi_and_cash
                      )}
                      .00
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        backgroundColor: "#D9EAD3 !important",
                        textAlign: "center",
                      }}
                    >
                      {countReportValues(
                        appointmentData?.payment_report,
                        "CONSULTATION"
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        backgroundColor: "#B6D7A8 !important",
                        textAlign: "center",
                      }}
                    >
                      {countReportValues(
                        appointmentData?.payment_report,
                        "TOTAL"
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          sx={{
            marginBottom: { xs: "50px", md: "0px" },
            width: { xs: "92vw", sm: "94vw", md: "60vw" },
            // overFlowY: "auto",
          }}
        >
          {" "}
          <TableContainer>
            <Table
              sx={{
                borderCollapse: "collapse",
                width: "100%",
                minWidth: {
                  xs: appointmentData?.data?.length > 0 ? "1400px" : "100%",
                  lg: "100%",
                },
                overflowY: "auto",
              }}
            >
              <TableHead>
                <StyledTableRow>
                  {TableCardData.tableRowHead4.map(
                    (rowBody, index) =>
                      (reportType == rowBody.type || rowBody.type == "ALL") && (
                        <StyledTableCell key={index}>
                          {rowBody.TblHead}
                        </StyledTableCell>
                      )
                  )}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {appointmentData?.data?.length > 0 ? (
                  appointmentData?.data?.map((RowCellData, index) => (
                    <StyledTableRow hover>
                      <StyledTableCell>
                        {index +
                          1 +
                          (appointmentData.current_page - 1) * rowsPerPage}
                      </StyledTableCell>
                      <StyledTableCell>
                        {UtilDateTime.formatYearMonthAndDayAsFullString(
                          RowCellData?.from_time || "-"
                        )}
                      </StyledTableCell>
                      {reportType == "CONSULTATION_REPORT" && (
                        <StyledTableCell>
                          {UtilDateTime.formatTimeAsHHMMTT(
                            RowCellData?.from_time || "-"
                          )}
                        </StyledTableCell>
                      )}
                      <StyledTableCell>
                        {RowCellData?.token || "-"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {RowCellData.patient_name || "-"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {RowCellData.mobile_number || "-"}
                      </StyledTableCell>
                      {reportType == "CONSULTATION_REPORT" && (
                        <StyledTableCell>
                          {bookingSourceConvert(RowCellData?.booking_source) ||
                            "-"}
                        </StyledTableCell>
                      )}
                      <StyledTableCell>
                        {convertToTitleCase(RowCellData?.booking_status) || "-"}
                      </StyledTableCell>
                      {reportType == "CONSULTATION_REPORT" && (
                        <>
                          <StyledTableCell>
                            {scanTypeConvert(RowCellData?.scan_type) || "-"}
                          </StyledTableCell>
                          <StyledTableCell>
                            {RowCellData?.referred_doctor || "-"}
                          </StyledTableCell>
                          <StyledTableCell>
                            {RowCellData?.in_que_time
                              ? UtilDateTime.formatTimeAsHHMMTT(
                                  RowCellData?.in_que_time
                                )
                              : "-"}
                          </StyledTableCell>
                          <StyledTableCell>
                            {RowCellData?.check_in_time
                              ? UtilDateTime.formatTimeAsHHMMTT(
                                  RowCellData?.check_in_time
                                )
                              : "-"}
                          </StyledTableCell>
                          <StyledTableCell>
                            {RowCellData?.check_out_time
                              ? UtilDateTime.formatTimeAsHHMMTT(
                                  RowCellData?.check_out_time
                                )
                              : "-"}
                          </StyledTableCell>
                        </>
                      )}
                      {reportType == "PAYMENT_REPORT" && (
                        <StyledTableCell>
                          {convertAmount(RowCellData?.vaccine_fee)}.00
                        </StyledTableCell>
                      )}
                      {reportType == "PAYMENT_REPORT" && (
                        <StyledTableCell>
                          {convertAmount(RowCellData?.consultation_fee)}.00
                        </StyledTableCell>
                      )}
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableRow>
                    <StyledTableCell colSpan={13} sx={{ textAlign: "center" }}>
                      No Appointment Found
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {appointmentData.total_records > 0 && (
            <TablePagination
              rowsPerPageOptions={[100, 200]}
              component="div"
              count={appointmentData.total_records}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Grid>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loaderopen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    </>
  );
}
