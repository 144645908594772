import React from 'react'
import { Routes, Route } from "react-router-dom";
import Dashboard from '../Components/pages/Dashboard/Dashboard';

import ManageAppointment from '../Components/pages/ManageAppointment/ManageAppointment';
import ManageUsers from '../Components/pages/ManageUsers/ManageUsers';
import SettingPage1 from '../Components/pages/Setting/SettingPage1';
import AddUsers from '../Components/pages/ManageUsers/AddUsers';
import ProfileView from '../Components/pages/Profileview/ProfileView';
import QRGenerator from '../Components/pages/QRGenerator/QRGenerator';
import NoAccessPage from '../Components/pages/NoAccessPage/NoAccessPage';
// import SignupPage from '../Components/pages/SignUp/SignupPage';
import PatientScanQr from '../Components/pages/QRGenerator/PatientQrCode';
import VerifyAppointment from '../Components/pages/QRGenerator/NurseVerifyAppointment';
import InvalidAppointment from '../Components/pages/QRGenerator/InvalidAppointment';
import SettingList from '../Components/pages/Setting/settingList';
import Reports from '../Components/pages/Reports/ReportsPage';


export default function AppRoutes() {
  return (
   <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/manageappointments" element={<ManageAppointment/>} />
      <Route path="/manageusers" element={<ManageUsers/>} />
      <Route path="/manageusers/addusers" element={<AddUsers/>} />
      <Route path="/manageusers/Editusers/:uid" element={<AddUsers/>} />
      <Route path="/manageusers/Viewusers/:uid" element={<AddUsers/>} />
      {/* <Route path="/Profile" element={<ProfileView/>} /> */}
      <Route path="/Profile" element={<AddUsers/>} />
      <Route path="/reports" element={<Reports/>} />
      <Route path="/all-settings" element={<SettingList/>} />
      <Route path="/settings/:user_uid" element={<SettingPage1/>} />
      <Route path="/settings/view-settings/:user_uid" element={<SettingPage1/>} />
      <Route path="/generateQR" element={<QRGenerator/>} />
      <Route path='/noaccesspage' element={<NoAccessPage/>} />
      <Route path='/patientqrscan/:doctor_uid' element={<PatientScanQr/>} />
      <Route path='/Nurseverifyappointments' element={<VerifyAppointment/>} />
      <Route path='/InvalidAppointment' element={<InvalidAppointment/>} />

   </Routes>
  )
}
