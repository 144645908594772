import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loggedInDetails:{ 
        user_name:"", 
        password:"", 
        full_name:"", 
        doctor_name:"", 
        user_id:"", 
        doctor_uid:"",
        hospital_or_clinic_name:"",
        user_type:"",
        update_render: 0
    }
}

export const userLoginDetails = createSlice({
    name:"loggedInDetails",
    initialState,
    reducers:{
        updateLoggedInData:(state, action) =>{
            state.loggedInDetails = action.payload
            console.log(state.loggedInDetails);
        }
    }
})

export const { updateLoggedInData } = userLoginDetails.actions;
export default userLoginDetails.reducer