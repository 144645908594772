import React from 'react';
import { Backdrop, Box, Button, Grid, Modal, Paper,Typography, IconButton  } from '@mui/material'
import "../Assets/css/custom-class.css"
import deleteicon from ".././Assets/images/DeletePopup/deleteImage.png"

export default function DeleteAppointment({openModalDelete, closeModalDelete}) {


  return (
    <>
        <Modal aria-labelledby="modal-title" aria-describedby="modal-description" open={openModalDelete} >
            <Backdrop open={openModalDelete} sx={{zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "rgba(0, 0, 0, 0.5)",}}>
                <Paper sx={{ width:"350px" , borderRadius:"10px",}}>
                    <Grid  sx={{borderRadius:"15px"}} container>
                        <Grid item xs={12} sx={{backgroundColor:"#EEF0FA" , borderRadius:"10px"}}>
                            <Box sx={{height:"70px",backgroundColor:"#FFD7D7",borderRadius:"10px 10px 0px 0px"}}>
                          
                            <IconButton sx={{marginTop:"35px",marginLeft:"142px",backgroundColor:"#D13C3C",height:"54px",width:"55px",'&:hover':{backgroundColor:"#D13C3C"},cursor:"default"}}>
                                <img src={deleteicon} alt='deleteicon' />
                            </IconButton>
                        
                            </Box>
                        </Grid>
                        <hr/>
                        <Grid item md={12} xs={12} sm={12} sx={{padding:"0% 5% 5%"}} >
                        <Box >
                            <Grid container >
                                <Grid item xs={12} sm={12}>
                                <Typography mt={2} sx={{display:"flex",justifyContent:"center",fontWeight:600,fontSize:"16px"}}>Are you sure you want to<br/> delete the appointment?</Typography>
                                
                                </Grid>             
                                
                             
                         
                                
                               
                                </Grid>

                            <Box>
                                <Box sx={{display:'flex',justifyContent:"center"}} mt={2} >
                                    <Button sx={{ mr: 2,backgroundColor:"#000000" , color:"#FFFFFF",height:"25px", 
                                    '&:hover': { background:'#000000' }, width:"95px",fontSize:"13px",fontWeight:600,borderRadius:"30px",textTransform:"none"
                                    }} onClick={closeModalDelete}>
                                        No
                                    </Button>
                                    <Button sx={{backgroundColor:"#41BA8F" , color:"#FFFFFF", height:"25px", 
                                    '&:hover': { background:'#41BA8F' }, width:"95px",fontSize:"13px",fontWeight:600,borderRadius:"30px",textTransform:"none"
                                    }}>
                                        Yes
                                    </Button>
                                </Box>
                            </Box>                   
                        </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Backdrop>
        </Modal>
    </>
  )
  
}
