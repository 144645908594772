import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    update_count: 0,
    update_setting: 0
}

export const updateRequest = createSlice({
    name:"update_request",
    initialState,
    reducers:{
        updateCount:(state, action) =>{
            state.update_count = action.payload
            // console.log(state);/
        },
        updateSetting:(state, action) =>{
            state.update_setting = action.payload
            // console.log(action.payload,"kkllll");
        }
    }
})

export const { updateCount } = updateRequest.actions;
export const { updateSetting } = updateRequest.actions;
export default updateRequest.reducer