import React from "react";
import { useSearchParams } from "react-router-dom";
import Controller from "../../../Controller/ApiController";
import { useEffect } from "react";
import { useState } from "react";
import InProgress from "../../../Assets/images/Verifyemail/inProgress.svg"
import LogoImae from "../../../Assets/images/Verifyemail/qgLogo.svg"
import Verified from "../../../Assets/images/Verifyemail/verified.svg"
import Expired from "../../../Assets/images/Verifyemail/expired.svg"
import Blocked from "../../../Assets/images/Verifyemail/blocked.svg"
import NotFound from "../../../Assets/images/Verifyemail/not_found.svg"
import AlreadyVerified from "../../../Assets/images/Verifyemail/already_verified.svg"
import { Box, Grid, Typography } from "@mui/material";


const dat = {
  SUCCESS: {
    text: "Your Email ID is verified",
    src: Verified
  },
  NOT_FOUND: {
    text: "Registered account not available for this Email-Id. Please contact QGlider Support",
    src: NotFound
  },
  SINGLE_USE_ONLY: {
    text: "Your email-id is already verified!",
    src: AlreadyVerified
  },
  LINK_EXPIRED: {
    text: "This link has expired! Please check your inbox for new verification email.",
    src: Expired
  },
  INACTIVE: {
    text: "Account with this Email-Id is blocked. Please contact QGlider Support.",
    src: Blocked
  },
  VERIFICATION_FAILED: {
    text: "Email Verification Failed.",
    src: Expired
  },
}

export default function VerifyEmail() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [verified, setVerified] = useState(true);
  const [inProgress, setInprogress] = useState(false);
  const [message, setMessage] = useState("")
  const [key, setKey] = useState("")


  useEffect(() => {
    async function FetchData() {
      let link = searchParams.get("link") || "";
      if (searchParams.get("link")) {
          let res = await Controller.ApiController(
            "POST",
            `/user/verify_email/${link}`
          );
          console.log(res,"status_key")
          setInprogress(true);
          if(res.type == "success" || res?.error.key == "SINGLE_USE_ONLY") {
            setVerified(true);
            setMessage(res.message)
            if(res?.error?.key == "SINGLE_USE_ONLY"){
              setKey(res.error.key)
            }
            else{
              setKey("SUCCESS")
            }
          } else{
            setVerified(false);
            setMessage(res.error.message)
            setKey(res.error.key) 
          }

      }
    }
    FetchData();
  }, []);

  return (
    <>
     <Grid container>
      <Grid item xs={12} mt={2} >
        <Box sx={{display:"flex",justifyContent:"center"}}>
        <img src={LogoImae} width="450px" alt='logoicon' />
      </Box>
      </Grid>
      <Grid item xs={12} mt={2} >
      <Box sx={{display:"flex",justifyContent:"center"}}>
        <img src={dat[key]?.src || InProgress } style={{width:"55px",height:"55px"}} alt='Verifiedicon' />
      </Box>
      <Box sx={{display:"flex",justifyContent:"center",mt:2}}>
        <Typography sx={{fontWeight:800,color:"#243665",fontSize:"35px"}}>{inProgress ? verified ? ("Thank you") : ("Email Verification Failed") : ("Verification in progress")}</Typography>
      </Box>
      <Box sx={{display:"flex",justifyContent:"center",mt:2}}>
        <Typography sx={{fontWeight:600,color:"#243665",fontSize:"30px",}}> {inProgress ? verified ?  dat[key]?.text :  dat[key]?.text : "Verification in progress"}</Typography>
      </Box>
      </Grid>
      </Grid>
      
    </>
  );
}
