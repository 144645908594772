import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import {Backdrop } from '@mui/material';
export default function Loader({loaderOpen}) {

  return (
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={loaderOpen}
  >
    <CircularProgress color="inherit"/>
  </Backdrop>

  )
}
