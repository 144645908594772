import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Card, CardContent } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AccessImage from "../../../Assets/images/noaccessimage.svg";

// ------ custom file and custom css file -------

import Header from "../../ReuseComponents/Header/Header";
import { Padding } from "@mui/icons-material";

export default function NoAccessPage() {
  return (
    <>
      <Header />
     
       
        <div style={{ marginTop: "15%" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={AccessImage} width={"150px"} />
          </div>
          <Typography
            sx={{
              fontSize: "20px",
              textAlign: "center",
              fontWeight: 500,
              marginTop: "5px",
              color:"#243665"
            }}
          >
            At present, you do not have access to any activity.
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              textAlign: "center",
              fontWeight: 500,
              marginTop: "5px",
              color:"#243665"
            }}
          >
            Please contact your System Administrator.
          </Typography>
        </div>
   
    </>
  );
}
