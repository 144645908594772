import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "../src/Components/Assets/Redux/app/store";

const Q_Glider = ReactDOM.createRoot(document.getElementById("Q-Glider"));
Q_Glider.render(
  // <React.StrictMode>
  <Provider store = { store }>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>

  //</React.StrictMode>
);

reportWebVitals();
