import React, { useEffect, useRef, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Typography,
  Select,
  FormControlLabel,
  RadioGroup,
  Radio,
  setRef,
  Card,
  LinearProgress,
  IconButton,
  Tooltip,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "../Assets/css/custom-class.css";
import validationRegExp from "../Configuration/ValidationConfig";
import PropTypes from "prop-types";
import Controller from "../Controller/ApiController";
import UtilDateTime from "../Constant/utillDateTime";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert } from "../Components/ReuseComponents/Alert/Alertmessage";
import "../Popup/bookappointment.css";
import dayjs from "dayjs";
import CancelAppointmentChild from "./CancelAppointmentChild";
import cancellIcon from "../Assets/images/DeletePopup/cancelIcon.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  BasicMenuItem,
  BasicSelect,
} from "../Components/ReuseComponents/Select/BasicSelect";
import {
  OutlinedInputField,
  OutlinedTextField,
} from "../Components/ReuseComponents/TextField/BasicTextField";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ContainedButton } from "../Components/ReuseComponents/Button/BasicButton";
import { updateCount } from "../Components/Assets/Redux/features/updateRequest/updateRequest";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "650px !important",
  height: "auto",
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  "@media (min-width: 720px) ": {
    width: "80%",
    // height:"auto",
  },
};

export default function GeneralSetting({ open, onClose }) {
  const [openCancelModel, setOpenCancelModel] = useState(false);
  const [paymentConfirmation, setPaymentConfirmation] = React.useState(false);
  const updateCountValue = useSelector( (state) => state.updateRequest.update_count);
  const userDetails = useSelector((state) => state?.userDetails?.data || {});
  const dispatch = useDispatch();

  const [loaderopen, setloaderOpen] = React.useState(false);
  const handleLoaderClose = () => {
    setloaderOpen(false);
  };
  const handleLoaderOpen = () => {
    setloaderOpen(true);
  };

  const intialFormData = {
    paymentFor: {
      value: "",
      error: false,
    },
  };

  const [formData, setFormData] = useState(intialFormData);

  const cancelModel = () => {
    setOpenCancelModel(true);
  };

  const cancelModelclose = () => {
    setOpenCancelModel(false);
    onClose();
  };

  const cancelModelcloseChild = () => {
    setOpenCancelModel(false);
  };

  const ObjectKeyValueToValue = (object) => {
    let parseObject = {};
    const keys = Object.keys(object);
    keys.map((val) => {
      parseObject = { ...parseObject, [val]: object[val].value };
    });
    return parseObject;
  };

  const ObjectValueToKeyValue = (object) => {
    let parseObject = {};
    const keys = Object.keys(object);
    keys.map((val) => {
      parseObject = {
        ...parseObject,
        [val]: {
          value: object[val],
          error: false,
        },
      };
    });
    return parseObject;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const postData = {
        finance_module: paymentConfirmation,
      };
      handleLoaderOpen();
      const response = await Controller.ApiController(
        "PUT",
        `/doctor/${userDetails.admin_uid}`,
        "?",
        postData
      );

      handleLoaderClose();
      if (response.type == "success") {
        Alert("success", "General setting successfully updated");
        onClose();
        dispatch(updateCount({ update_count: updateCountValue + 1 }));
      } else {
        Alert("error", response?.error?.message || "Something Went Wrong");
      }
    } catch (e) {
      handleLoaderClose();
      Alert("error", "Something Went Wrong");
      console.log("General setting submit error", e.message);
    }
  };

  const getDoctorData = async (e) => {
    try {
      handleLoaderOpen();
      const response = await Controller.ApiController(
        "GET",
        `/doctor/${userDetails.admin_uid}`,
        "?",
        ""
      );

      handleLoaderClose();
      if (response.type == "success") {
        setPaymentConfirmation(!!response.data.finance_module);
      } else {
        Alert("error", response?.error?.message || "Something Went Wrong");
      }
    } catch (e) {
      handleLoaderClose();
      Alert("error", "Something Went Wrong");
      console.log("General setting doctor get error", e.message);
    }
  };

  useEffect(() => {
    if (userDetails) {
      getDoctorData();
    }
  }, [userDetails]);

  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={open}
      >
        <Backdrop
          open={open}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <Paper
            className="bookmodal"
            sx={{
              width: { md: "400px", xs: "320px" }, // Set the maximum width for larger screens
              margin: "auto",
            }}
          >
            <Grid sx={{ borderRadius: "15px" }} container>
              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: "#EEF0FA",
                  borderTopLeftRadius: "15px",
                  borderTopRightRadius: "15px",
                }}
              >
                <Box
                  sx={{
                    padding: { md: "10px 25px", xs: "10px 10px" },
                    display: "flex",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#243665",
                      marginLeft: "0.6px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    General Settings
                  </Typography>
                </Box>
              </Grid>
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{ padding: { md: "0px 25px", xs: "0px 10px" } }}
              >
                <FormControlLabel
                  value="morning"
                  sx={{ fontStyle: "14px" }}
                  control={
                    <Checkbox
                      sx={{
                        "&.Mui-checked": { color: "#243665" },
                        fontStyle: "14px",
                      }}
                      checked={paymentConfirmation}
                      onChange={(event) =>
                        setPaymentConfirmation(event.target.checked)
                      }
                    />
                  }
                  label={
                    <Typography style={{ fontSize: "14px" }}>
                      Enable this option to show payment options
                    </Typography>
                  }
                  labelPlacement="end"
                />
                <Box
                  display={"flex"}
                  sx={{ width: "100%", textAlign: "center" }}
                  mt={1}
                >
                  <ContainedButton
                    sx={{
                      // mt: 3,
                      mb: 2,
                      mr: 2,
                      backgroundColor: "#D13C3C",
                      color: "#FFFFFF",
                      borderRadius: "50px",
                      height: "40px",
                      "&:hover": { background: "#D13C3C" },
                      width: "135px",
                      textTransform: "none",
                    }}
                    onClick={cancelModel}
                  >
                    Cancel
                  </ContainedButton>
                  <ContainedButton
                    type="submit"
                    sx={{
                      // mt: 3,
                      mb: 2,
                      borderRadius: "50px",
                      height: "40px",
                      width: "135px",
                    }}
                  >
                    Submit
                  </ContainedButton>
                </Box>

                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={loaderopen}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </Box>
            </Grid>
          </Paper>
        </Backdrop>
      </Modal>
      {openCancelModel && (
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          open={cancelModel}
        >
          <Backdrop
            open={cancelModel}
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <Paper sx={{ width: "350px", borderRadius: "10px" }}>
              <Grid sx={{ borderRadius: "15px" }} container>
                <Grid
                  item
                  xs={12}
                  sx={{ backgroundColor: "#EEF0FA", borderRadius: "10px" }}
                >
                  <Box
                    sx={{
                      height: "70px",
                      backgroundColor: "#FFD7D7",
                      borderRadius: "10px 10px 0px 0px",
                    }}
                  >
                    <IconButton
                      sx={{
                        marginTop: "35px",
                        marginLeft: "142px",
                        backgroundColor: "#D13C3C",
                        height: "54px",
                        width: "55px",
                        "&:hover": { backgroundColor: "#D13C3C" },
                        cursor: "default",
                      }}
                    >
                      <img src={cancellIcon} alt="deleteicon" />
                    </IconButton>
                  </Box>
                </Grid>
                <hr />
                <Grid item md={12} xs={12} sm={12} sx={{ padding: "0% 5% 5%" }}>
                  <Box>
                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        <Typography
                          mt={2}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          Are you sure you want
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          to cancel the activity ?
                        </Typography>
                      </Grid>
                    </Grid>

                    <Box>
                      <Box
                        sx={{ display: "flex", justifyContent: "center" }}
                        mt={2}
                      >
                        <Button
                          sx={{
                            mr: 2,
                            backgroundColor: "#000000",
                            color: "#FFFFFF",
                            height: "25px",
                            "&:hover": { background: "#000000" },
                            width: "95px",
                            fontSize: "13px",
                            fontWeight: 600,
                            borderRadius: "30px",
                            textTransform: "none",
                          }}
                          onClick={cancelModelcloseChild}
                        >
                          No
                        </Button>
                        <Button
                          sx={{
                            backgroundColor: "#41BA8F",
                            color: "#FFFFFF",
                            height: "25px",
                            "&:hover": { background: "#41BA8F" },
                            width: "95px",
                            fontSize: "13px",
                            fontWeight: 600,
                            borderRadius: "30px",
                            textTransform: "none",
                          }}
                          onClick={cancelModelclose}
                        >
                          Yes
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Backdrop>
        </Modal>
      )}
    </>
  );
}
