import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CancelAppointmentChild from "./CancelAppointmentChild";
import cancellIcon from ".././Assets/icons/addUserTick.svg";
import {
  DesktopDatePicker,
  DesktopTimePicker,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import Controller from "../Controller/ApiController";
import { object } from "prop-types";
import ValueFormat from "../Service/valueFormate";
import CustomizedButtons from "../Components/ReuseComponents/Buttons/ColorButton";
import { Alert } from "../Components/ReuseComponents/Alert/Alertmessage";
import Loader from "../Components/ReuseComponents/Loader/loader";
import { useSelector } from "react-redux";
import UtilDateTime from "../Constant/utillDateTime";
import {
  BasicMenuItem,
  BasicSelect,
  MultipleSelect,
} from "../Components/ReuseComponents/Select/BasicSelect";
import { useDispatch } from "react-redux";
import { bulkMoveRefresh } from "../Components/Assets/Redux/features/Appointment/bulkMoveRefresh";
import { ContainedButton } from "../Components/ReuseComponents/Button/BasicButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "650px !important",
  height: "auto",
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  "@media (min-width: 720px) ": {
    width: "80%",
    // height:"auto",
  },
};

export default function BulkMove({ open, onClose, fetchGetAPIData }) {
  const [currentDate, setCurrentDate] = useState(dayjs(new Date()));
  const minDate = dayjs(currentDate);
  const minTime = dayjs(currentDate);
  // const storedDate = useSelector( (state) => state.dateFilter.date)
  const storedDate = dayjs(new Date());
  const [value, setValue] = React.useState(dayjs(storedDate));
  const [appointmentSuccessData, setAppointmentSuccessData] = useState({
    moved: 0,
    unmoved: 0
  });
  const [time, setTime] = useState({
    fromTime: dayjs(currentDate),
    toTime: dayjs(currentDate),
    update: "",
  });
  const [successModal, setSuccessModal] = useState(false);
  const [fromTimeError, setFromTimeError] = useState(false);
  const [cancelBulkModal, setCancelBulkModal] = useState(false);
  const [submitValidation, setSubmitValidation] = useState(true);
  const [cancelData, setcancelData] = useState({
    queCount: 0,
    notarrivedCount: 0,
    totalCount: 0,
  });
  const [uidData, setUIDData] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [resonMessage, setResonMessage] = useState("");
  const [loaderOpen, setLoaderOpenn] = useState(false);
  const [popupType, setPopUpType] = useState("");
  const docterUid = localStorage.getItem("DoctorUid");
  const userType = localStorage.getItem("user_type");
  const [users, setUsers] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [formData, setFormData] = useState({
    user_uid: {
      value: "",
      error: false,
    },
  });
  const dispatch = useDispatch();
  const [selectedDoctors, setSelectedDoctors] = useState("");
  const [selectedFromDoctor, setSelectedFromDoctor] = useState("");
  const [filteredUsersForToDoctor, setFilteredUsersForToDoctor] = useState([]);
  const [selectedToDoctor, setSelectedToDoctor] = useState("");
  const userDetails = useSelector((state) => state?.userDetails?.data || {});  

  // const handlechange = (e) => {
  //   const { value, name } = e.target;
  //   let filerValue = typeof value === "string" ? value.split(",") : value;
  //   filerValue = filerValue.filter((val) => val != "Select");
  //   setSelectedDoctors(filerValue);
  // };
  const handlechange = (e) => {
    const { value } = e.target;
    setSelectedDoctor(value !== "Select" ? value : ""); // Avoid setting "Select" as value
  };

  const manageApiRef = useSelector(
    (state) => state.manageAppointmentSlice.appointment
  );
  // const filteredUsersForToDoctor = users.filter(
  //   (user) => !selectedDoctors.includes(user) // Exclude doctors selected in "From Doctor"
  // );

  const handleFromDoctorChange = (e) => {
    const selectedUser = e.target.value;
    
    setSelectedFromDoctor(selectedUser);
  
    // Filter 'To Doctor' based on the selected user type
    if (selectedUser?.user_type === "DOCTOR") {
      // Show only doctors excluding the selected one
      const filteredDoctors = users.filter(
        (user) => user.user_type === "DOCTOR" && user.full_name !== selectedUser.full_name
      );
      setFilteredUsersForToDoctor(filteredDoctors);
    } else if (selectedUser?.user_type === "SCAN") {
      // Show only scan users
      const filteredScans = users.filter((user) => user.user_type === "SCAN" && user.full_name !== selectedUser.full_name);
      setFilteredUsersForToDoctor(filteredScans);
    } else {
      setFilteredUsersForToDoctor([]); // No valid selection
    }
  };
  
  const handleToDoctorChange = (e) => {
    setSelectedToDoctor(e.target.value);
  };

  const handleSuccessOpenModal = () => {
    setSuccessModal(true);
  };

  const handleSuccessCloseModal = () => {
    setSuccessModal(false);
    onClose();
    setAppointmentSuccessData({
      moved: 0,
      unmoved: 0
    });
  };

  const handleChange = (event) => {
    setSelectedDoctor(event.target.value);
  };

  useEffect(() => {
    let currentdate = new Date();
    currentdate = currentdate.setHours(0, 0, 0, 0);

    if (new Date(currentdate).toISOString() == storedDate) {
      currentdate = new Date().toISOString();
      setCurrentDate(dayjs(storedDate).startOf("minute"));
    } else {
      currentdate = storedDate;
      setCurrentDate(dayjs(storedDate).startOf("minute"));
    }
  }, [storedDate]);

  useEffect(() => {
    if (value !== "") {
      if (
        time.fromTime !== "" &&
        // !time.fromTime.isBefore(currentDate) &&
        uidData.length > 0 &&
        selectedToDoctor
      ) {
        setSubmitValidation(false);
      } else {
        setSubmitValidation(true);
      }
    }
  }, [value, time, currentDate, uidData, selectedToDoctor]);

  const userRequest = async () => {
    try {
      let queryParams = {
        "filter.user_type": "DOCTOR,SCAN",
        "filter.doctor_uid": userDetails.admin_uid || "",
      };

      if(userDetails.user_type == "DOCTOR" || userDetails.user_type == "SCAN") {
        queryParams = {...queryParams, "filter.user_type": userDetails.user_type}
      }else if(userDetails.user_type != "SUPER_ADMIN"){
        queryParams = {...queryParams, 
          // "filter.user_uid_type": "MAPED_DOCTOR",
          // "filter.user_uid": userDetails?.user_uid || "",

        }
      }

      const response = await Controller.ApiController(
        "GET",
        "/manage_users",
        "",
        queryParams
      );
      if (response.type == "success") {
        console.log(response?.data, "responsemonresponse2");
        setUsers(response?.data || []);
        if(userDetails.user_type == "DOCTOR" || userDetails.user_type == "SCAN") {
          const findUser = response.data.find((val) => val.user_uid == userDetails.user_uid);
          const filterUser = response.data.filter((val) => val.user_uid != userDetails.user_uid);
          setSelectedFromDoctor(findUser);
          setFilteredUsersForToDoctor(filterUser);
          if(filterUser.length == 1) {
            setSelectedToDoctor(filterUser[0]);
          }
        }
      }
    } catch (e) {
      console.log("User Request: ", e.message);
    }
  };

  useEffect(() => {
    if (userDetails.filter) {
      userRequest();
    }
  }, [userDetails]);

  const filteredUsers = () => {
    return users.filter((user) => {

      if (userDetails.user_type == "DOCTOR" || userDetails.user_type == "SCAN") {
        // Show only the logged-in doctor
        return user.user_uid === userDetails.user_uid;
      } else if (userType === "DOCTOR") {
        // Show only the logged-in doctor
        return user.user_uid === userDetails.user_uid;
      } else if (userType === "SCAN") {
        return user.user_type === "SCAN";  // Display only scan users
      } else if (userType === "NURSE" || userType === "RECEPTIONIST" || userType === "LAB_TECHNICIAN") {
        return userDetails?.maped_doctor?.some((val) => val?.user_uid == user?.user_uid) && (user.user_type === "DOCTOR" || user.user_type === "SCAN");
      } else {
        return true; // Show all users for other roles
      }
    });
  }

  const getCancleAppointment = async () => {
    setLoaderOpenn(true);
    let uidDataTemp = [];
    const requestParams = {
      "filter.slot_start_time": dayjs(storedDate)
        .set("hours", time.fromTime.$H)
        .set("minutes", time.fromTime.$m)
        .set("seconds", 0)
        .set("millisecond", 0)
        .toISOString(),
      "filter.slot_end_time": dayjs(storedDate).endOf('day').toISOString(),
        // .set("hours", time.toTime.$H)
        // .set("minutes", time.toTime.$m)
        // .set("seconds", 0)
        // .set("millisecond", 0)
        // .toISOString(),
      // "filter.slot_status": "NOT_ARRIVED",
      "filter.doctor_uid": docterUid,
      "filter.paginate": "NO",
      "filter.appointment_date": storedDate || "",
      "filter.user_uid": selectedFromDoctor.user_uid || "",
      "filter.slot_status": "IN_QUE,NOT_ARRIVED",
      "filter.consultation_type": "NORMAL,REVIEW_APPOINTMENT"
    };
    const result = await Controller.ApiController(
      "GET",
      "/appointment",
      "?",
      requestParams
    );

    const resultData = ValueFormat.parseAsArray(result.data);
    const queCount = ValueFormat.parseAsInt(result.in_que_count);
    const notArrived = ValueFormat.parseAsInt(result.not_arrived_count);
    const totalRecords = ValueFormat.parseAsInt(result.total_records);
    resultData.map((value) => {
      const appointment_uid = ValueFormat.parseAsString(value.appointment_uid);
      uidDataTemp.push(appointment_uid);
    });
    setUIDData(uidDataTemp);
    setcancelData((prev) => {
      return {
        ...prev,
        queCount: queCount,
        notarrivedCount: notArrived,
        totalCount: totalRecords,
      };
    });
    setLoaderOpenn(false);
  };

  const submitCancel = async () => {
    if (!selectedToDoctor.user_uid) {
      Alert("error", "Please select a doctor before proceeding.");
      return;
    }

    const uidDataList = {
      user_uid: selectedToDoctor.user_uid,
      appointment_uids: uidData,
      reason: resonMessage,
    };

    const result = await Controller.ApiController(
      "POST",
      "/move_appointment",
      "",
      uidDataList
    );
    const status = result.type;
    if (status === "success") {
      Alert("success", "Appointments moved successfully!");
      manageApiRef();
      // cancelModelclose();
      handleSuccessOpenModal();
      setCancelBulkModal(false);
      setAppointmentSuccessData({
        moved: result?.appointment_moved || 0,
        unmoved: result?.appointment_unmoved || 0
      })
      // setTimeout(() => {
      //   fetchGetAPIData();
      // }, 500);
      // getCancleAppointment();
    }
    console.log("result1: ", result);
  };

  const fetchData = () => {
    // let addTwoTime = dayjs(time.fromTime).add(10, "minutes");
    // if (addTwoTime <= time.toTime) {
    getCancleAppointment();
    // } else {
    setFromTimeError(true);

    // }
  };

  useEffect(() => {
    if (
        !!time.fromTime &&
        selectedFromDoctor
    ) {    
      
      setButtonDisabled(false);
    } else setButtonDisabled(true);

  }, [time.fromTime, selectedFromDoctor]);

  const cancelModel = (type) => {
    setPopUpType(type);
    setCancelBulkModal(true);
  };

  const cancelModelclose = () => {
    setTime({
      fromTime: dayjs(currentDate),
      toTime: dayjs(currentDate),
    });
    setcancelData({
      queCount: 0,
      notarrivedCount: 0,
      totalCount: 0,
    });
    setFromTimeError(false);
    setCancelBulkModal(false);
    setSelectedFromDoctor("");
    setSelectedToDoctor("");
    setResonMessage("");
    handleSuccessCloseModal();
  };

  const cancelModelcloseChild = () => {
    setCancelBulkModal(false);
  };

  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={open}
        onClose={onClose}
      >
        <Backdrop
          open={open}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <Paper
            sx={{
              maxWidth: {
                xl: "50%",
                lg: "60%",
                md: "80%",
                sm: "590px",
                xs: "310px",
              },
              borderRadius: "10px",
            }}
          >
            <Loader loaderOpen={loaderOpen} />

            <Grid sx={{ borderRadius: "10px" }} container>
              <Grid
                item
                xs={12}
                sx={{ backgroundColor: "#EEF0FA", borderRadius: "10px", borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px" }}
              >
                <Box sx={{ padding: { md: "10px 25px", xs: "10px 15px" } }}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#243665",
                    }}
                  >
                    Bulk Move
                    {/* - {UtilDateTime.formatMonthDateYear(storedDate)} */}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                container
                sx={{ padding: { md: "10px 25px", xs: "10px 15px" } }}
              >
                <Grid
                  item
                  md={5}
                  xs={12}
                  sx={{
                    borderRight: { md: "1px solid #000000", xs: "none" },
                  }}
                >

                  <Box fullWidth mt={{ xs: 2, md: 1 }}>
                    <Grid container>
                      <Grid item xs={5.5} sm={3.5}>
                        {" "}
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: "400",
                            color: "#000000",
                            height: "30px",
                            alignContent: "center",
                          }}
                        >
                          From Doctor
                        </Typography>
                      </Grid>
                      <Grid item xs={6.5} sm={8.5}>
                      <BasicSelect
                        sx={{ width: { xs: "100%", sm: "180px" } }}
                        value={selectedFromDoctor || "default"}
                        onChange={handleFromDoctorChange}
                        displayEmpty
                      >
                      <MenuItem value="default" style={{ display: "none" }}>
                        <span style={{ color: "#bbb7b7" }}>Select</span>
                      </MenuItem>
                        {Array.isArray(filteredUsers()) && filteredUsers().length > 0 ? (
                          filteredUsers().map((user, i) => (
                            <MenuItem key={i} value={user}>
                              <ListItemText
                                primary={user.full_name}
                                sx={{
                                  "& .MuiListItemText-primary": {
                                    fontSize: "13px",
                                  },
                                }}
                              />
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled sx={{fontSize:"12px"}}>No Doctors Available</MenuItem>
                        )}
                      </BasicSelect>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box fullWidth mt={2}>
                    <Grid container>
                      <Grid item xs={5.5} sm={3.5}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: "400",
                            color: "#000000",
                            height: "30px",
                            alignContent: "center",
                          }}
                        >
                          From
                        </Typography>
                      </Grid>
                      <Grid item xs={6.5} sm={8.5}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopTimePicker
                            sx={{
                              "& .MuiInputBase-root": {
                                width: { xs: "100%", sm: "180px" }, // Adjust the width as needed
                                height: "30px", // Adjust the width as needed
                                overflow: "hidden",
                              },
                              "& .MuiInputBase-input": {
                                fontSize: "13px",
                                padding: "5px 0px 4px 10px",
                              },
                              "& .MuiSvgIcon-root": {
                                width: "16px", // Adjust icon size if necessary
                                height: "16px", // Adjust icon size if necessary
                              },
                            }}
                            // minTime={minTime}
                            slotProps={{
                              textField: { size: "small" },
                            }}
                            value={time.fromTime}
                            onChange={(newValue) => {
                              setTime((prev) => ({
                                ...prev,
                                fromTime: newValue,
                                update: newValue,
                              }));
                              setFromTimeError(false);
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={4}>
                    <ContainedButton
                        sx={{
                          backgroundColor: "#2C73EB",
                          padding: "0px 30px",
                          color: "#FFFFFF",
                          borderRadius: "50px",
                          height: "40px",
                          "&:hover": { backgroundColor: "#2C73EB" },
                          textTransform: "none",
                        }}
                        onClick={fetchData}
                        disabled={buttonDisabled}
                      >
                        Fetch Appointments
                    </ContainedButton>
                  </Box>
                </Grid>
                <Grid
                  item
                  md={7}
                  xs={12}
                  sx={{
                    padding: { md: "0px 0px 0px 30px" },
                  }}
                >
                    <Grid container mt={{ xs: 2, md: 1 }}>
                      <Grid item xs={5.5} sm={3}>
                      <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: "400",
                            color: "#000000",
                            height: "30px",
                            alignContent: "center",
                          }}
                        >
                          To Doctor
                        </Typography>
                      </Grid>
                      <Grid item xs={6.5} sm={9}>
                        <BasicSelect
                          sx={{
                            width: { xs: "100%", sm: "180px" },
                            height: "30px",
                            "& .MuiSelect-select": { fontSize: "13px" },
                          }}
                          value={selectedToDoctor || "Select"}
                          onChange={handleToDoctorChange}
                          displayEmpty
                          
                        >
                          <MenuItem value="Select" style={{ display: "none" }}>
                            <span style={{ color: "#bbb7b7" }}>Select</span>
                          </MenuItem>
                          {filteredUsersForToDoctor.length > 0 ? (
                            filteredUsersForToDoctor.map((user, i) => (
                              <MenuItem key={i} value={user}>
                                <ListItemText
                                  primary={user.full_name}
                                  sx={{
                                    "& .MuiListItemText-primary": {
                                      fontSize: "13px",
                                    },
                                  }}
                                />
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem disabled sx={{fontSize:"12px"}}>Not Available</MenuItem>
                          )}
                        </BasicSelect>
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        marginTop: "4%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#000",
                        }}
                      >
                        Total Appointments :
                        <span
                          style={{
                            fontSize: "15px",
                            fontWeight: "600",
                            color: "#000",
                            marginLeft: "3px",
                            marginTop: "10px",
                          }}
                        >
                          {cancelData.queCount + cancelData.notarrivedCount}
                        </span>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginTop: "4%",
                      }}
                    >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#000",
                          }}
                        >
                          {`Appointments for the patients moved to ${selectedToDoctor.full_name}.`}
                        </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginTop: "2%",
                      }}
                    >
                        <FormLabel
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#000",
                          }}
                        >
                          Reason for move : (optional)
                        </FormLabel>
                        <TextField
                          fullWidth
                          // sx={{width:{md:"100%", xs:"50%"}}}
                          onChange={(e) => setResonMessage(e.target.value)}
                        />
                    </Box>
                  <Box sx={{ marginLeft: { xs: "5px", sm: "5px", md: "" } }}>
                    <Box display={"flex"} alignItems={"center"}>
                      <Button
                        type="submit"
                        sx={{
                          mt: 3,
                          mb: 2,
                          mr: 2,
                          backgroundColor: "#D13C3C",
                          color: "#FFFFFF",
                          border: "1px solid #FFF",
                          borderRadius: "50px",
                          height: "40px",
                          "&:hover": { background: "#D13C3C" },
                          width: "154px",
                          textTransform: "none",
                        }}
                        onClick={() => cancelModel("CANCEL")}
                      >
                        Cancel
                      </Button>
                      <ContainedButton
                        type="submit"
                        disabled={submitValidation}
                        sx={{
                          mt: 3,
                          mb: 2,
                          width: "154px",
                          padding: "0px 30px",
                          borderRadius: "50px",
                          height: "40px",
                          textTransform: "none",
                        }}
                        onClick={() => cancelModel("BULK_SUBMIT")}
                      >
                        Submit
                      </ContainedButton>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Backdrop>
      </Modal>

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={successModal}
      >
        <Backdrop
          open={successModal}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <Paper sx={{ width: "350px", borderRadius: "10px" }}>
            <Box sx={{  borderRadius: "10px" }}>
              <Box
                sx={{
                  height: "60px",
                  backgroundColor: "#FFD7D7",
                  borderRadius: "10px 10px 0px 0px",
                }}
              >
                <Box sx={{ textAlign: "center", paddingTop: "40px" }}>
                  <img
                    src={cancellIcon}
                    alt="deleteicon"
                    style={{ width: "40px" }}
                  />
                </Box>
              </Box>
              <Box sx={{ margin: 5, marginBottom: 2 }}>
                <Typography
                  mt={2}
                  sx={{
                    display: "flex",
                    alignItems: "left",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  <span style={{ width: "165px" }}>Appointments moved</span>:<span style={{fontWeight: 450}}>&nbsp;{appointmentSuccessData.moved}</span>
                </Typography>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "left",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  <span style={{ width: "165px" }}>Appointments unmoved</span>:<span style={{fontWeight: 450}}>&nbsp;{appointmentSuccessData.unmoved}</span>
                </Typography>
              </Box>

              <Box sx={{ textAlign: "center", marginBottom: 2 }}>
                <Button
                  sx={{
                    backgroundColor: "#41BA8F",
                    color: "#FFFFFF",
                    height: "25px",
                    "&:hover": { background: "#41BA8F" },
                    width: "95px",
                    fontSize: "13px",
                    fontWeight: 600,
                    borderRadius: "30px",
                    textTransform: "none",
                  }}
                  onClick={handleSuccessCloseModal}
                >
                  Ok
                </Button>
              </Box>
            </Box>
          </Paper>
        </Backdrop>
      </Modal>
      {cancelBulkModal && (
        <CancelAppointmentChild
          openModalCancel={cancelModel}
          closeModalCancel={cancelModelclose}
          cancelModelcloseChild={cancelModelcloseChild}
          popupType={popupType}
          submitCancel={submitCancel}
        />
      )}
    </>
  );
}
