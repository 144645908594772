import React  from 'react'
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Button, Typography } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius:"10px"
  };


export default function ConfirmationPop({Data , yesSubmit , NoSubmit}) {
  console.log(Data)
  return (

      <Box sx={style}>
           <Box sx={{backgroundColor: Data.Purpose == "Confirmation" ? "#FFD6B1" : "#FFD7D7",padding:"20px",borderTopLeftRadius:"10px",borderTopRightRadius:"10px"}} id="modal-modal-title">
             <Box sx={{backgroundColor: Data.Purpose == "Confirmation" ? "#E77B18" : "#BC123E",padding:"5px",display:"flex",justifyContent:"center",
               alignItems:"center",borderRadius:"20px",width:"40px",height:"40px",position:"absolute",left:"42%"}}>
                {Data.Purpose == "Cancel" ? (<DoDisturbIcon sx={{fill:"white"}} />) : Data.Purpose == "Delete" ? (<DeleteOutlinedIcon  sx={{fill:"white"}}/>) : Data.Purpose == "Confirmation" ? (<SettingsIcon  sx={{fill:"white"}}/>) : ""}
             </Box>
           </Box>
           <Box sx={{padding:"25px 30px 20px",textAlign:"center"}}>
             <Typography id="modal-modal-description" sx={{ mt: 0 ,fontWeight:"bold" }}>
                    {Data.message}
             </Typography>
           </Box>
           <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",gap:"15px",paddingBottom:"15px"}}>
             <Button  onClick={() => NoSubmit()} sx={{backgroundColor:"#000000", color:"white",minWidth:"95px",padding:"2px",borderRadius:"20px", '&:hover': {
               background: '#000000',}}}>no</Button>
             <Button onClick={() => yesSubmit(Data)} sx={{backgroundColor:"#41BA8F", color:"white",minWidth:"95px",padding:"2px",borderRadius:"20px", '&:hover': {
               background: '#41BA8F',}}}>yes</Button>
             </Box>
         </Box>

  )
}




