import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  ListItemText,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import TimeAdjustmentProceed from "./TimeAdjustmentProceed";
import ValueFormat from "../Service/valueFormate";
import Controller from "../Controller/ApiController";
import CustomizedButtons from "../Components/ReuseComponents/Buttons/ColorButton";
import { Alert } from "../Components/ReuseComponents/Alert/Alertmessage";
import Loader from "../Components/ReuseComponents/Loader/loader";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import UtilDateTime from "../Constant/utillDateTime";
import {
  BasicMenuItem,
  BasicSelect,
  MultipleSelect,
} from "../Components/ReuseComponents/Select/BasicSelect";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { ContainedButton } from "../Components/ReuseComponents/Button/BasicButton";

export default function TimeAdjustment({ open, onClose }) {
  const Navigate = useNavigate();
  const appointmentData = useSelector(
    (state) => state.appointmentSlice.appointment
  );
  const manageApiRef = useSelector(
    (state) => state.manageAppointmentSlice.appointment
  );
  // const storedDate = useSelector( (state) => state.dateFilter.date)
  const storedDate = dayjs(new Date());
  const [timeAdjustType, setTimeAdjustType] = useState("DELAY");
  const [proceedModal, setProceedModal] = useState(false);
  const [conformStatus, setConformStatus] = useState(true);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [minTime, setMinTime] = useState(dayjs(currentDate).startOf("day"));
  const [duration, setDuration] = useState({ hours: 0, minutes: 0 });
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  // const [duration, setDuration] = useState("");
  const [durationDis, setDurationDis] = useState("");
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    user_uid: {
      value: "",
      error: false,
    },
  });

  const [time, setTime] = useState({
    fromTime: dayjs(currentDate),
    toTime: dayjs(currentDate),
  });
  const [todayEndTime, setTodayEndTime] = useState(
    dayjs(storedDate).set("hour", 23).set("minutes", 59).set("second", 59)
  );
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [timeAdjustData, setTimeAdjustData] = useState({
    uidData: [],
    delayDuration: 0,
    adjustment: "",
    startFrom: "",
    notArrivedata: 0,
  });
  const [resonMessage, setResonMessage] = useState("");
  const [popupType, setPopupType] = useState("");
  const [loaderOpen, setLoaderOpen] = useState(false);
  const userDetails = useSelector((state) => state?.userDetails?.data || {});
  const userType = localStorage.getItem("user_type");
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const handlechange = (e) => {
    const { value, name } = e.target;
    let filerValue = typeof value === "string" ? value.split(",") : value;
    filerValue = filerValue.filter((val) => val != "Select");
    setSelectedDoctors(filerValue);
  };

  const handleProceedCloseModal = () => {
    setProceedModal(false);
  };
  const docterUid = localStorage.getItem("DoctorUid");

  useEffect(() => {
    console.log(manageApiRef, "manageApiRef");
  }, [manageApiRef]);

  useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  });

  const userRequest = async () => {
    try {
      let queryParams = {
        "filter.user_type": "DOCTOR,SCAN",
        "filter.doctor_uid": userDetails.admin_uid || "",
      };

      if(userDetails.user_type == "DOCTOR" || userDetails.user_type == "SCAN") {
        queryParams = {...queryParams, "filter.user_type": userDetails.user_type}
      }else if(userDetails.user_type != "SUPER_ADMIN"){
        queryParams = {...queryParams, 
          "filter.user_uid_type": "MAPED_DOCTOR",
          "filter.user_uid": userDetails?.user_uid || "",

        }
      }

      const response = await Controller.ApiController(
        "GET",
        "/manage_users",
        "",
        queryParams
      );
      console.log(response, "responsemonresponse1");
      if (response.type == "success") {
        console.log(response?.data, "responsemonresponse2");
        if(userDetails.user_type == "DOCTOR" || userDetails.user_type == "SCAN") {
          const findUser = response.data.find((val) => val.user_uid == userDetails.user_uid);
          const filterUser = response.data.filter((val) => val.user_uid == userDetails.user_uid);
          setSelectedDoctors(filterUser);
          setUsers(filterUser);
        }else{
          setUsers(response?.data || []);
          if(Array.isArray(response?.data) && response?.data.length == 1) {            
            const filterUser = response.data;
            setSelectedDoctors(filterUser);
          }
        }
      }
    } catch (e) {
      console.log("User Request: ", e.message);
    }
  };

  useEffect(() => {
    if (userDetails.filter) {
      userRequest();
    }
  }, [userDetails]);

  const filteredUsers = users.filter((user) => {
    if (userType === "DOCTOR") {
      // Show only the logged-in doctor
      return user.user_uid === userDetails.user_uid;
    } else if (userType === "SCAN") {
      return user.user_type === "SCAN";  // Display only scan users
    } else if (userType === "NURSE" || userType === "RECEPTIONIST") {
      return user.user_type === "DOCTOR" || user.user_type === "SCAN"; // Nurses/Receptionists see doctors and scan users
    } else {
      return true; // Show all users for other roles
    }
  });

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setCurrentDate(new Date());
  //   }, 10 * 1000);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  // useEffect(() => {
  //   setMinTime(dayjs(currentDate));

  //   const afterDate = time.fromTime.isAfter(currentDate);
  //   if (!afterDate) {
  //     setTime((prev) => {
  //       return { ...prev, fromTime: dayjs(currentDate) };
  //     });
  //   }
  // }, [currentDate]);

  // const handleDurationChange = (event) => {
  //   const value = event.target.value;

  //   // Validate input format (HH:MM)
  //   // const regex = /^(\d{0,2}):(\d{0,2})$/;
  //   // if (!regex.test(value)) {
  //   //   // Invalid format, handle accordingly (e.g., show an error message)
  //   //   return;
  //   // }

  //   const [hours, minutes] = value
  //     .split(":")
  //     .map((v) => (v ? parseInt(v, 10) : 0));

  //   const dataHours = Number(hours).toString().padStart(2, "0");
  //   const dataMinutes = Number(minutes).toString().padStart(2, "0");
  //   let validHours;
  //   let validMinutes;
  //   // Ensure that hours and minutes are within the allowed range
  //   if (
  //     dataHours.length > 0 &&
  //     dataHours.length <= 2 &&
  //     dataMinutes.length > 0 &&
  //     dataMinutes.length <= 2
  //   ) {
  //     validHours = Math.min(Math.max(0, dataHours), 99);
  //     validMinutes = Math.min(Math.max(0, dataMinutes), 59);
  //     setDuration({ hours: validHours, minutes: validMinutes });
  //   }
  //   // Update the "To Time" based on the entered duration and "From Time"
  //   if (timeAdjustType === "DELAY") {
  //     if (time.fromTime) {
  //       const toTime = time.fromTime
  //         .add(validHours, "hours")
  //         .add(validMinutes, "minutes");
  //       setTime((prev) => ({ ...prev, toTime }));
  //     }
  //   } else {
  //     if (time.fromTime) {
  //       const toTime = time.fromTime
  //         .subtract(validHours, "hours")
  //         .subtract(validMinutes, "minutes");
  //       setTime((prev) => ({ ...prev, toTime }));
  //     }
  //   }
  // };
  // const handleDurationChange = (event) => {
  //   const input = event.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
  //   const hours = input.substring(0, 2);
  //   const minutes = input.substring(2, 4);

  //   setDuration({
  //     hours: Math.min(parseInt(hours) || 0, 99), // Ensure hours are between 0 and 99
  //     minutes: Math.min(parseInt(minutes) || 0, 59), // Ensure minutes are between 0 and 59
  //   });
  // };
  const handleDurationChange = (event) => {
    const input = event.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    let formattedInput = "";
    let hour = 0;
    let minutes = 0;

    if (input.length <= 2) {
      // If less than or equal to 2 characters, use as hours
      hour = input;
      formattedInput = hour;
      // setDuration(prev => {return{...prev, hours:formattedInput}})
    } else {
      // If more than 2 characters, format as hours and minutes
      hour = input.substring(0, 2);
      minutes = input.substring(2, 4);
      formattedInput = `${hour}:${minutes}`;
      // setDuration(prev => {return{...prev, hours:input.substring(0, 2), split:":", minutes:input.substring(2, 4)}})
    }

    console.log("formattedInput: ", formattedInput);

    setDurationDis(formattedInput);
    setDuration((prev) => {
      return { ...prev, hours: Number(hour), minutes: Number(minutes) };
    });
    if (timeAdjustType === "DELAY") {
      if (time.fromTime) {
        const toTime = time.fromTime
          .add(Number(hour), "hours")
          .add(Number(minutes), "minutes");
        setTime((prev) => ({ ...prev, toTime }));
      }
    } else {
      if (time.fromTime) {
        const toTime = time.fromTime
          .subtract(Number(hour), "hours")
          .subtract(Number(minutes), "minutes");
        setTime((prev) => ({ ...prev, toTime }));
      }
    }
  };

  const handleTimeChange = (newValue) => {
    if (newValue) {
      const hour = newValue.hour();
      const minutes = newValue.minute();
      setDuration({ hours: hour, minutes: minutes });

      if (timeAdjustType === "DELAY") {
        const toTime = time.fromTime
          .add(hour, "hour")
          .add(minutes, "minute");
        setTime((prev) => ({ ...prev, toTime }));
      } else {
        const toTime = time.fromTime
          .subtract(hour, "hour")
          .subtract(minutes, "minute");
        setTime((prev) => ({ ...prev, toTime }));
      }
    }
  };

  // const handleDurationChange = (event) => {
  //   const value = event.target.value;
  //   const [hours, minutes] = value
  //     .split(":")
  //     .map((v) => (v ? parseInt(v, 10) : 0));
  //   setDuration({ hours, minutes });

  //   // Update the "To Time" based on the entered duration and "From Time"
  //   if (timeAdjustType === "DELAY") {
  //     if (time.fromTime) {
  //       const toTime = time.fromTime
  //         .add(hours, "hours")
  //         .add(minutes, "minutes");
  //       setTime((prev) => ({ ...prev, toTime }));
  //     }
  //   } else {
  //     if (time.fromTime) {
  //       const toTime = time.fromTime
  //         .subtract(hours, "hours")
  //         .subtract(minutes, "minutes");
  //       setTime((prev) => ({ ...prev, toTime }));
  //     }
  //   }
  // };

  const handleFromTimeChange = (newValue) => {
    const fromTime = dayjs(newValue);
    let toTime;
    // Update the "To Time" based on the entered duration and new "From Time"
    if (timeAdjustType === "DELAY") {
      toTime = fromTime
        .add(duration.hours, "hours")
        .add(duration.minutes, "minutes");
    } else {
      toTime = fromTime
        .subtract(duration.hours, "hours")
        .subtract(duration.minutes, "minutes");
    }
    setTime({ fromTime, toTime });
  };

  useEffect(() => {
    const fromTime = time.fromTime;
    let toTime;
    // Update the "To Time" based on the entered duration and new "From Time"
    if (timeAdjustType === "DELAY") {
      toTime = fromTime
        .add(duration.hours, "hours")
        .add(duration.minutes, "minutes");
    } else {
      toTime = fromTime
        .subtract(duration.hours, "hours")
        .subtract(duration.minutes, "minutes");
    }
    setTime({ fromTime, toTime });
  }, [timeAdjustType, time.fromTime]);

  useEffect(() => {
    if (
      (duration.hours !== 0 || duration.minutes !== 0) &&
      !!time.fromTime && selectedDoctors.length > 0
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [duration.hours, duration.minutes, time.fromTime, selectedDoctors]);

  useEffect(() => {
    if (timeAdjustData.uidData.length > 0) {
      setConformStatus(false);
    } else {
      setConformStatus(true);
    }
    console.log(timeAdjustData.uidData, "ta-data");
  }, [timeAdjustData.uidData]);

  useEffect(() => {}, []);

  const getTimeDelayAppointment = async () => {
    setLoaderOpen(true);
    let uidDataTemp = [];
    const requestParams = {
      "filter.slot_start_time": dayjs(storedDate)
        .set("hours", time.fromTime.$H)
        .set("minutes", time.fromTime.$m)
        .set("seconds", 0)
        .set("millisecond", 0)
        .toISOString(),
      "filter.slot_end_time": todayEndTime,
      "filter.slot_status": "NOT_ARRIVED",
      "filter.doctor_uid": docterUid,
      "filter.paginate": "NO",
      "filter.appointment_date": storedDate || "",
      "filter.user_uid": selectedDoctors?.map((val) => val.user_uid)?.join(",") || ""
    };
    try{
    
      const result = await Controller.ApiController(
      "GET",
      "/appointment",
      "?",
      requestParams
    );
    console.log("getTimeAppointment:", result);

    const resultData = ValueFormat.parseAsArray(result.data);
    const queCount = ValueFormat.parseAsInt(result.in_que_count);
    const notArrived = ValueFormat.parseAsInt(result.not_arrived_count);
    const totalRecords = ValueFormat.parseAsInt(result.total_records);
    resultData.map((value) => {
      const appointment_uid = ValueFormat.parseAsString(value.appointment_uid);
      uidDataTemp.push(appointment_uid);
    });
    console.log(result, "uidDataTemp");
    setTimeAdjustData((prev) => {
      return {
        ...prev,
        uidData: uidDataTemp,
        delayDuration: `${duration.hours}:${duration.minutes}`,
        adjustment: timeAdjustType,
        startFrom: time.fromTime,
        notArrivedata: notArrived,
      };
    });
    setLoaderOpen(false);
  } catch(e) {
    console.log("Error in fetch slot:", e.message);
    setLoaderOpen(false);
  }
  };

  const fetchData = () => {
    getTimeDelayAppointment();
  };

  // useEffect(() => {
  //   if (!dayjs(time.toTime).isSame(time.fromTime)) {
  //     getTimeDelayAppointment();
  //   }
  // }, [duration, time.toTime]);

  const postTimeDelay = async () => {
    setLoaderOpen(true);
    const requestParams = {
      not_arrived_appointment_uids: timeAdjustData.uidData,
      delay_duration: timeAdjustData.delayDuration,
      adjustment: timeAdjustData.adjustment,
      start_from: timeAdjustData.startFrom,
      reason: resonMessage,
    };

    console.log("requestParams :", requestParams);
    const postData = await Controller.ApiController(
      "PUT",
      "/appointment_time_adjustment",
      "",
      requestParams
    );
    setLoaderOpen(false);
    const status = postData.type;

    if (status === "success") {
      Alert("success", "Consultation delay updated successfully!");
      closePopUp();
      manageApiRef();
      // appointmentData();
    }

    console.log("postData: ", postData);
  };

  const handleProceedOpenModal = (type) => {
    setPopupType(type);
    setProceedModal(true);
  };

  const closePopUp = () => {
    setDurationDis("");
    setDuration((prev) => {
      return { ...prev, hours: 0, minutes: 0 };
    });
    onClose();
  };
  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={open}
        onClose={onClose}
      >
        <Backdrop
          open={open}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <Paper
            sx={{
              maxWidth: {
                xl: "55%",
                lg: "60%",
                md: "80%",
                xs: "95%",
              },
              borderRadius: "10px",
            }}
          >
            <Loader loaderOpen={loaderOpen} />
            <Grid container sx={{ borderRadius: "10px" }}>
              <Grid
                item
                xs={12}
                sx={{ backgroundColor: "#EEF0FA", borderRadius: "10px" }}
              >
                <Box sx={{ padding: { md: "20px 48px", xs: "20px 18px" } }}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#243665",
                    }}
                  >
                    Consultation Delay
                    {/* -{" "}{UtilDateTime.formatMonthDateYear(storedDate)} */}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                container
                sx={{
                  margin: { md: "10px 48px", xs: "10px 18px" },
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={5}
                  sx={{ borderRight: { sm: "1px solid #000" } }}
                >
                  <Grid container>
                    <Grid item xs={12} mt={2}>
                      <Grid container>
                        <Grid item xs={4}>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: "400",
                              color: "#000000",
                              height: "30px",
                              alignContent: "center",
                            }}
                          >
                            Doctor
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          
            <MultipleSelect
              sx={{ width: "110px" }}
              value={selectedDoctors.length > 0 ? selectedDoctors : ["Select"]}
              onChange={handlechange}
              typeOfValue={selectedDoctors.length > 0 && "OBJECT"}
              typeOfKey="full_name"
            >
              <BasicMenuItem value="Select" style={{ display: "none" }}>
                Select
              </BasicMenuItem>
              {Array.isArray(filteredUsers) && filteredUsers.length > 0 ? filteredUsers.map((val, i) => (
                <BasicMenuItem key={i} value={val}>
                  <ListItemText
                    primary={val.full_name}
                    sx={{
                      "& .MuiListItemText-primary": {
                        fontSize: "13px",
                      },
                    }}
                  />
                  <Checkbox
                    sx={{ padding: 0, marginLeft: "10px", '&.Mui-checked': { color: "#243665", }, }}
                    checked={selectedDoctors.includes(val)}
                    size="small"
                  />
                </BasicMenuItem>
              )) : <BasicMenuItem disabled>
                  No Doctors Available
                </BasicMenuItem>}
            </MultipleSelect>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Grid item xs={12} mt={2}> */}
                      <Grid container sx={{display:"flex",  alignItems:"center"}}>
                        <Grid item xs={4}>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: "400",
                              color: "#000000",
                              height: "30px",
                              alignContent: "center",
                            }}
                          >
                            Duration
                          </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{display:"flex",  alignItems:"center"}}>
                          <FormControl sx={{ ml: 0, mt: 1, minWidth: 70,  }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DesktopTimePicker
                                views={['hours', 'minutes']}
                                format="HH:mm"
                                ampm={false}
                                sx={{
                                  minWidth: "50px", // Reduced minimum width
                                  "& .MuiInputBase-root": {
                                    width: "70px",  // Set desired width
                                    height: "30px", // Set desired height
                                    padding: "6px 0px", // Adjust padding as needed
                                  },
                                  "& .MuiInputBase-input": {
                                    fontSize: "14px",
                                    paddingLeft: "10px",
                                    paddingRight: "0px"
                                  },
                                  ".MuiIconButton-root": {
                                    marginRight: "-3px",
                                    paddingLeft: "0px",
                                  },
                                  "& .MuiSvgIcon-root": {
                                    width: "16px",
                                    height: "16px",
                                  },
                                  "& .MuiInputAdornment-root": {
                                    marginLeft: "0px"
                                  }
                                }}
                                value={dayjs().hour(duration.hours).minute(duration.minutes)}
                                onChange={handleTimeChange}
                              />
                            </LocalizationProvider>
                          </FormControl>
                            <Typography
                              sx={{
                                fontSize: "10px",
                                color: "#999999",
                                height: "30px",
                                alignContent: "center",
                                mt: 1,
                                ml: "5px"
                              }}
                            >
                              HH:MM
                            </Typography>
                        </Grid>
                      </Grid>
                    {/* </Grid> */}
                    <Grid item xs={12} mt={1}>
                      <Grid container>
                        <Grid item xs={4}>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: "400",
                              color: "#000000",
                              height: "30px",
                              alignContent: "center",
                            }}
                          >
                            Starting From
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>

                              <TimePicker
                                sx={{
                                  "& .MuiInputBase-root": {
                                    width: "110px",
                                    overflow: "hidden",
                                    height: "30px", // Adjust the width as needed
                                    overflowX: "hidden",
                                    overFlowY: "hidden",
                                  },
                                  "& .MuiInputBase-input": {
                                    fontSize: "13px",
                                    paddingLeft: "10px",
                                  },
                                  "& .MuiSvgIcon-root": {
                                    width: "16px", // Adjust icon size if necessary
                                    height: "16px", // Adjust icon size if necessary
                                  },
                                }}
                                minTime={minTime}
                                slotProps={{ textField: { size: "small" } }}
                                value={time.fromTime}
                                onChange={handleFromTimeChange}
                              />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                      <Box>
                      <ContainedButton
                        sx={{
                          backgroundColor: "#2C73EB",
                          marginTop: "20px",
                          padding: "0px 30px",
                          color: "#FFFFFF",
                          borderRadius: "50px",
                          height: "40px",
                          "&:hover": { backgroundColor: "#2C73EB" },
                          textTransform: "none",
                        }}
                        onClick={fetchData}
                        disabled={buttonDisabled}
                      >
                        Fetch Appointments
                    </ContainedButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xl={7}
                  lg={7}
                  md={7}
                  xs={12}
                  sm={7}
                  sx={{
                    padding: {
                      xl: "0px 0px 0px 30px",
                      lg: "0px 0px 0px 30px",
                      md: "0px 0px 0px 30px",
                      sm: "0px 0px 0px 30px",
                      xs: "0px 0px 0px 20px",
                    },
                  }}
                >
                  <Box>
                    <Grid
                      sx={{
                        marginTop: "4%",
                        marginLeft: { xs: "-4px", sm: "-4px", md: "" },
                      }}
                    >
                      <Grid
                        sx={{
                          textAlign: {
                            xl: "left",
                            lg: "left",
                            md: "left",
                            sm: "left",
                            xs: "left",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#000",
                          }}
                        >
                          Consultation delay will be notified to{" "}
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "#000",
                            }}
                          >
                            {timeAdjustData.notArrivedata} patient(s)
                          </span>{" "}
                          <span
                            style={{
                              display: height > width ? "" : "none",
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#000",
                            }}
                          >
                            who have not arrived, through SMS or WhatsApp.
                          </span>
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#000",
                            marginTop: { sm: "", xs: "5px" },
                            display: {
                              sm: "none",
                              xs: "none",
                              md: "inline",
                              xl: "inline",
                              lg: "inline",
                            },
                          }}
                        >
                          who have not arrived, through SMS or WhatsApp.
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      sx={{
                        marginTop: "9%",
                        marginLeft: { xs: "-4px", sm: "-4px", md: "" },
                      }}
                    >
                      <Grid>
                        <FormLabel
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#000",
                          }}
                        >
                          Reason for delay : (optional)
                        </FormLabel>
                        <TextField
                          fullWidth
                          size="xl"
                          sx={{ mt: 0 }}
                          onChange={(e) => setResonMessage(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      sx={{
                        marginTop: { md: 2.2, xl: 2.2, lg: 2.2, xs: 1 },
                        marginLeft: { xs: "10px", sm: "10px", md: "" },
                      }}
                    >
                      <Button
                        type="submit"
                        sx={{
                          mt: 3,
                          mb: 2,
                          mr: 2,
                          backgroundColor: "#D13C3C",
                          color: "#FFFFFF",
                          border: "1px solid #FFF",
                          borderRadius: "50px",
                          height: "40px",
                          "&:hover": { background: "#D13C3C" },
                          width: "154px",
                          fontSize: "14px",
                          textTransform: "capitalize",
                        }}
                        onClick={() => handleProceedOpenModal("CANCEL")}

                        // onClick={closePopUp}
                      >
                        Cancel
                      </Button>
                      <ContainedButton
                        type="submit"
                        sx={{
                          mt: 3,
                          mb: 2,
                          width: "154px",
                          padding: "0px 30px",
                          borderRadius: "50px",
                          height: "40px",
                          textTransform: "none",
                        }}
                        onClick={() => handleProceedOpenModal("SUBMIT")}
                        disabled={conformStatus}
                      >
                        Submit
                      </ContainedButton>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Backdrop>
      </Modal>
      <TimeAdjustmentProceed
        postTimeDelay={postTimeDelay}
        openModalProceed={proceedModal}
        closeProceedModalCancel={handleProceedCloseModal}
        popupType={popupType}
        closePopUp={closePopUp}
      />
    </>
  );
}
