import React, { useState, useEffect } from 'react'
import {Box, Button, Card, CardContent, Grid, Icon,Modal,Typography} from "@mui/material";
import GliderLogo from "../../../Assets/qgliderlogo.png"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Controller from "../../../Controller/ApiController";
import { useLocation,useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from "react-redux";
import { updateActualDateData } from '../../Assets/Redux/features/StoreData/updateDate';
import { Link } from "react-router-dom";
//  ------custom file -------
import AddIcon from '@mui/icons-material/Add';
 import './Header.css'
import UtilDateTime from '../../../Constant/utillDateTime';
import BookAppointment from '../../../Popup/BookAppointment';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius:"10px"
};

export default function Header() {
  const Location = useLocation();  
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const today = new Date().toDateString();
  const [ currentDateTime, setCurrentDateTime ] = useState(new Date());
  const [bookModalOpen, setBookModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const currentDay = new Intl.DateTimeFormat('en-US',{ weekday: 'long',}).format(currentDateTime);
  const userDetails = useSelector( (state) => state.userDetails.data);
  const [fullName, setFullName] = useState("")
  const [bookedStatusList, setBookedStatusList] = useState([])
  const dispatch = useDispatch();
  const bookedData = useSelector( (state) => state.headerData.booked_data)
  
  const DoctorName = localStorage.getItem('full_name') 
  const TotalCount = localStorage.getItem('booked_count')
  const storedDate = useSelector( (state) => state.dateFilter.date)
  const appointmentDatas = useSelector( (state) => state.settingAppointmentData.appointment_data)

  

    const changeDate = async(value) => {
      console.log(value, storedDate ,"storedDate")
      dispatch(updateActualDateData(value))
    }

    useEffect(()=>{
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    })
    
    useEffect(()=>{
      if(bookedData.length > 0) {
        setBookedStatusList(bookedData)
      }
    },[bookedData])





  
  let Doctor_id = localStorage.getItem('DoctorUid')

  //FETCHING USER DETAILS FROM STORE
  useEffect(()=>{
    if(userDetails?.user_full_name){
      setFullName(userDetails?.user_full_name || "");
    }
  },[userDetails])


  useEffect(()=>{
    const intervalId = setInterval(()=>{
      setCurrentDateTime(new Date());
    }, 1000);
    return () =>{
      clearInterval(intervalId);
    };
  }, []);

  const formattedDay = new Intl.DateTimeFormat('en-US',{day:'2-digit',}).format(currentDateTime);
  const formattedMonth = new Intl.DateTimeFormat('en-US',{month:'short'}).format(currentDateTime);

  const logout = () => {
    setOpen(true);
  }

  const logoutUser = () => {
    localStorage.removeItem("hospital_name")
    localStorage.removeItem("booked_count")
    // localStorage.removeItem("switchOn")
    localStorage.removeItem("DoctorName")
    localStorage.removeItem("full_name")
    localStorage.removeItem("DoctorUid")
    // localStorage.removeItem("user_name")
    navigate("/login")

  }
  const handleClose = () => setOpen(false);


    
  const handleBookModalOpen = () => { 
    if(appointmentDatas==="APPOINTMENT") {
      setBookModalOpen(true);
    }
    // Alert("success", "success message");
  };

  const handleBookModalClose = () => {
    setBookModalOpen(false);
  };

  



  return (
      <Box>
        <Grid container spacing={0} sx={{padding:"10px 0px"}}>
          <Grid  item xs={12} sm={12} md={3.5} lg={3.5} xl={4} >
           <Box display={{xs: "none", md: "block"}}> {Location.pathname.includes("/dashboard") &&<h3 style={{marginTop:"8px"}}>Hello,<span style={{color:"#243665", fontWeight:1000, fontSize:"26px"}}> {fullName || "--" }</span></h3>}</Box>
           
            <Box display={{md: "none"}}>
            
           <Box sx={{display:"flex", justifyContent:"space-between !important" }}>
            <Box>
            <img src={GliderLogo} alt={GliderLogo + ".logo"} width={180} />
            </Box> 
            <Box sx={{display:"flex",alignItems:"center", }}>
             <Box onClick={handleBookModalOpen} sx={{display:"flex",alignItems:"center", marginTop:"-7px",justifyContent:"center",backgroundColor:appointmentDatas == "NO_APPOINTMENT" ? "#999999" : "#41BA8F",borderRadius:"25px" , width:"27px",height:"27px"}}>
              <AddIcon style={{fill:'white'}}/>
          </Box>
          {bookModalOpen && <BookAppointment open={bookModalOpen} onClose={handleBookModalClose} />}
          {!Location.pathname.includes("/Profile") && <Box component={Link} to="/Profile" className="iconspace"><AccountCircleIcon sx={{fill:"#41BA8F",fontSize:"xx-large"}} /></Box>}
              <Box className="iconspace" onClick={logout}><ExitToAppIcon  sx={{fontSize:"xx-large"}}/></Box>
            </Box>
           </Box>
          {Location.pathname.includes("/dashboard") && <Box><h3 style={{marginTop:"8px"}}>Hello,<span style={{color:"#243665", fontWeight:1000, fontSize:"26px"}}> {fullName || "--" }</span></h3></Box>}
           </Box>
          </Grid>     
          
           {Location.pathname.includes("/dashboard") && 
            <Grid item xs={12} sm={12} md={8.5} lg={8.5} xl={8} sx={{display:Location.pathname === "/noaccesspage" ? "none" : "block", }} >            
            <Box sx={{textAlign:{md:"right", sm:"left", xs:"left"}}}>{bookedStatusList?.map((value, index) => (
            <button className='header-btn' key={index} onClick={() => changeDate(value.appointment_date)} style={{textAlign:"center",border: value.appointment_date == storedDate && "3.5px solid #22B175", whiteSpace:"nowrap"}}>
            <span style={{fontWeight:"700", fontSize:"22px", marginRight:"10px", paddingTop:"30px", whiteSpace:"nowrap", }}>{value.count || "0"}</span>
             Booked Appointments
            &nbsp;(&nbsp;{UtilDateTime.parseIsoToDateString(value.appointment_date)}&nbsp;)
            </button>))} 
            </Box>
            </Grid> 
           }

        </Grid>

        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <Box sx={{backgroundColor:"#FFD7D7",padding:"20px",borderTopLeftRadius:"10px",borderTopRightRadius:"10px"}} id="modal-modal-title">
            <Box sx={{backgroundColor:"#BC123E",padding:"5px",display:"flex",justifyContent:"center",
              alignItems:"center",borderRadius:"20px",width:"40px",height:"40px",position:"absolute",left:"42%"}}>
              <LogoutIcon sx={{fill:"white"}} />
            </Box>
          </Box>
          <Box sx={{padding:"25px 30px 20px",textAlign:"center"}}>
            <Typography id="modal-modal-description" sx={{ mt: 0 }}>
            Are you sure you want to Logout 
            {/* {DeleteUserDetails ? DeleteUserDetails.user_full_name:""}? */}
            </Typography>
          </Box>
          <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",gap:"15px",paddingBottom:"15px"}}>
            <Button onClick={handleClose} sx={{backgroundColor:"#000000", color:"white",minWidth:"95px",padding:"2px",borderRadius:"20px", '&:hover': {
              background: '#000000',}}}>no</Button>
            <Button onClick={logoutUser}  sx={{backgroundColor:"#41BA8F", color:"white",minWidth:"95px",padding:"2px",borderRadius:"20px", '&:hover': {
              background: '#41BA8F',}}}>yes</Button>
            </Box>
        </Box>
      </Modal>
      </Box>
  )
}
