import React from 'react'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../ReuseComponents/Alert/alertmessage.css"

export const Alert = (Type, message) => {
     switch (Type) {
          case "success":
               toast.success(message,{
                    position:"top-center",                                 
                    theme: "colored",
                    className: 'toastAlign'
                });
               break;
          case "error":
               toast.error(message,{                    
                    position:"top-center",
                    
               });
               break;
          case "warning":
               toast.warning(message,{
                    position:"top-center",   
               });
               break;
          case "info":
               toast.info(message,{
                    position:"top-center",   
               });
               break;
               default:
               toast.info(message,{
                    position:"top-center",   
               });
               break;
     }
}




