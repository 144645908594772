
import { ApiService } from "../Service/ApiService.js"

let Controller = {
    async ApiController(methodType, apiUrl, BodyData, queryParams) {
        try {
            let res;
            if (methodType === "GET") {

                res = await ApiService.callGET(apiUrl, BodyData, queryParams || "")

            } else if (methodType === "POST") {

                res = await ApiService.callPOST(apiUrl,queryParams || "")
            } else if (methodType === "PUT") {
                res = await ApiService.callPUT(apiUrl, queryParams)
            }
            else {
                res = await ApiService.callDELETE(apiUrl, BodyData,queryParams || "")
            }

            let res_data = JSON.parse(res);
            console.log(res_data)

            if (res_data.status === "SUCCESS") {
                return res_data.data;
            } 
            else if (res_data.status === "FAILED") {
                return res_data
            } else {
                throw new Error(JSON.stringify(res_data.error));
            }

        } catch (error) {
            throw new Error(error.message || "Request Error");
        }
    },
};

export default Controller;
