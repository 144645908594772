import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Table,
  TablePagination,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  FormControl,
  Menu,
  MenuItem,
  Button,
  InputLabel,
  Typography,
  TextField,
  Divider,
  InputAdornment,
  IconButton,
  Paper,
  Tooltip,
  Avatar,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CancelIcon from "@mui/icons-material/Cancel";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

// ------ custom import files --------
import "./TableData.css";
import TableCardData from "../../../Constant/Dashboard.json";
import BioDetails from "../../ReuseComponents/Bio/BioDetails";
// import RiEditBoxFill from "../../../../src/Assets/Images/AppoinmentIcon/editIcon.svg";
// import MdDelete from "../../../../src/Assets/Images/DeletePopup";
import BookAppointment from "../../../Popup/BookAppointment";
import DeleteAppointment from "../../../Popup/DeletePopup";
import PropTypes from "prop-types";
import Controller from "../../../Controller/ApiController";
import UtilDateTime from "../../../Constant/utillDateTime";
import { Alert } from "../../../Components/ReuseComponents/Alert/Alertmessage";
import { useDispatch, useSelector } from "react-redux";
import delayIcon from "../../../Assets/icons/timeDelay.svg";
import { appointmentReferesh } from "../../Assets/Redux/features/Appointment/appointmentReferesh";
import { Link } from "react-router-dom";
import AES_ENCRYPT_DECRYPT from "../../../util/encryptDecrypt";
import BreadCrumbs from "../../ReuseComponents/BreadCrumbs/BreadCrumbs";
import Header from "../../ReuseComponents/Header/Header";
import GeneralSetting from "../../../Popup/generalSetting";
import { ContainedButton } from "../../ReuseComponents/Button/BasicButton";

const tableHeader = [
  "S.No",
  "Doctor Name",
  "Department",
  "User Type",
  "Contact Number",
  "Assigned Nurse / Receptionist",
  "Settings Type",
  // "Status",
  "Actions",
];

export default function SettingList(props) {
  const dispatch = useDispatch();
  const { opens, filterInQue, setUpdateTopWidget } = props;

  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [users, setUsers] = useState({});
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [loaderopen, setloaderOpen] = React.useState(false);
  const [nextDayDate, setNextDayDate] = useState(false);
  const doctorUID = localStorage.getItem("DoctorUid");

  const storedDate = useSelector((state) => state.dateFilter.date);
  const filterData = useSelector((state) => state.filterData.data);
  const userDetails = useSelector((state) => state.userDetails.data);
  const [reRenderFunction, setReRenderFunction] = useState(false);

  let UserTypeAccess = localStorage.getItem("user_type")

  const handleLoaderClose = () => {
    setloaderOpen(false);
  };
  const handleLoaderOpen = () => {
    setloaderOpen(true);
  };

  useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  });

  useEffect(() => {
    if(reRenderFunction) {
    const currentUrl = new URL(window.location.href);
    let pageNumber = currentUrl.searchParams.get("page");
    let perPage = currentUrl.searchParams.get("perPage");
    pageNumber = parseInt(pageNumber) || 0;
    perPage = parseInt(perPage) || 0;
    if(pageNumber > 0 || perPage > 4) {
      userRequest(pageNumber, perPage);
      setPage(pageNumber);
      setRowsPerPage(perPage)
    }else {
      startOfThePage();
    }
  } else {
    setReRenderFunction(true);
  }
  }, [count]);

  
  const userRequest = async (currentPage, perPage) => {
    try {

      handleLoaderOpen();
      let queryParams = { 
        "filter.doctor_uid": doctorUID, 
        "filter.user_type": "DOCTOR,SCAN", 
        "filter.keyword":searchKeyword,
        page: currentPage + 1,
        count: perPage,
      };
      
    if (userDetails.user_uid && ["SCAN", "DOCTOR"].includes(userDetails.user_type)) {
      queryParams = { ...queryParams, "filter.user_uid": userDetails.user_uid, "filter.user_uid_type": "MAPED_USER" };
    } else if(filterData.user_uid.length > 0) {
      queryParams = { ...queryParams, "filter.user_uid": filterData.user_uid.join(",") };
    } else if(filterData.maped_user_uid.length > 0) {
      queryParams = { ...queryParams, "filter.user_uid": filterData.maped_user_uid.join(",") };
    } else if(userDetails.user_uid && !["SCAN", "DOCTOR"].includes(userDetails.user_type)) {
      queryParams = { ...queryParams, "filter.user_uid": userDetails.user_uid, "filter.user_uid_type": "MAPED_DOCTOR" };
    }

      const response = await Controller.ApiController(
        "GET",
        "/manage_users",
        "",
        queryParams
      );
      handleLoaderClose();
      console.log(response, "responsemonresponse1");
      if (response.type == "success") {
        console.log(response?.data, "responsemonresponse2");
        setUsers(response || {});
      }
    } catch (e) {
      console.log("User Request: ", e.message);
      handleLoaderClose();
    }
  };
  console.log(users, "usersususers");

  useEffect(() => {    
    if(userDetails.filter && filterData.filter) {
      startOfThePage()
    }
  }, [filterData, userDetails, searchKeyword]);

  const refereshPage = () => {
    setCount((current) => current + 1);
    setUpdateTopWidget((current) => current + 1);
  };

  useEffect(() => {
    dispatch(appointmentReferesh(refereshPage));
  }, []);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDeleteOpenModal = () => {
    setDeleteModal(true);
  };

  const handleDeleteCloseModal = () => {
    setDeleteModal(false);
  };

  const startOfThePage = () => {
    addURLQueryParam( 0, 15);
    userRequest(0, 15);
    setPage(0);
    setRowsPerPage(15);
  }

  const handleChangePage = (event, value) => {
    setPage(value);
    addURLQueryParam(value, rowsPerPage);
    userRequest(value, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));    
    addURLQueryParam(0, parseInt(event.target.value));
    setPage(0);
    userRequest(0, event.target.value);
  };

  const addURLQueryParam = (page, rowsPerPage) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", page);
    url.searchParams.set("perPage", rowsPerPage);
    window.history.replaceState({}, '', url);
  };
  
  const convertFirstLetterToUpperCase = (inputString) => {
    return inputString ? inputString
      .toLowerCase()
      .split(/[ _]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ') : "";
  };

  return (
    <>
      <Grid container spacing={0}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ display: { xs: "", sm: "none" } }}
      >
        <Header />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ marginTop: { xs: "0px", sm: "0px" }, marginBottom: "25px" }}
      >
        <BreadCrumbs
          crumbs={["Dashboard", "All Settings"]}
          paths={["/dashboard", "/all-settings"]}
          selected={(crumb) => console.log("hello", crumb)}
        />
      </Grid>
        <Grid
          sx={{ padding: "5px 0px 0px 0px" }}
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <BioDetails />
        </Grid>
      </Grid>
      <Grid
        sx={{
          padding: "5px 0px 0px 0px",
          marginBottom: { xs: "50px", md: "0px" },
          marginTop:1,
        }}
        container
        spacing={1}
      >
        <Grid item sm={12} md={12} lg={12}>
          <Paper sx={{ width: { sm: "100%", xs: "380px" } }}>
            <TableContainer>
              <Table
                // stickyHeader
                sx={{ minWidth: { sm: "800px", xs: "300px" } }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow sx={{ whiteSpace: { sm: "normal", xs: "nowrap" } }}>
                    {tableHeader.map((RowHeadData, index) => (
                      <TableCell
                        style={{
                          color: "#676666",
                          fontSize: "14px",                          
                          fontWeight: 600,
                          padding: "5px",
                          // width:"550px",
                          backgroundColor: "#EEF0FA",
                          whiteSpace:"nowrap"
                        }}
                        key={index}
                      >
                        {RowHeadData}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {users?.data?.length > 0 ? (
                    users?.data?.map((RowCellData, index) => (
                      <TableRow key={index}>
                        <TableCell className="RowCellData">
                          {index +
                            1 +
                            (users.current_page - 1) * rowsPerPage}
                        </TableCell>
                        <TableCell
                          sx={{ height: "50px" }}
                          className="RowCellData"
                        >
                          {RowCellData?.full_name ||
                            "-"}
                        </TableCell>
                        <TableCell className="RowCellData">
                            {RowCellData?.department || RowCellData?.department || "-"}
                        </TableCell>
                        <TableCell className="RowCellData">
                            {convertFirstLetterToUpperCase(RowCellData?.user_type) || convertFirstLetterToUpperCase(RowCellData?.user_type) || "-"}
                        </TableCell>
                        <TableCell className="RowCellData">
                        {RowCellData?.mobile_number || "-"}
                        </TableCell>
                        <TableCell className="RowCellData">
                        { Array.isArray(RowCellData?.nurse_or_receptionist) ? RowCellData?.nurse_or_receptionist?.map((val) => val.full_name).join(", ") || "-" : "-"}
                        </TableCell>
                        <TableCell className="RowCellData">
                        {RowCellData?.active_setting_type || "-"}
                        </TableCell>
                        {/* <TableCell className="RowCellData">
                        {RowCellData?.status || RowCellData?.status || "-"}
                        </TableCell> */}
                        <TableCell className="RowCellData" sx={{textAlign: "left"}}>
                        
                        <Box sx={{display:"flex",justifyContent:"left"}} >
                               <Tooltip title="View" arrow slotProps={{tooltip: { sx: {backgroundColor:"#f5f5f9", color: "rgba(0, 0, 0, 0.87)",  border: '1px solid #dadde9', '& .MuiTooltip-arrow':{ color:"#f5f5f9"}}}}}> <Button  size="small" sx={{minWidth:"auto"}}>
                               <Link to={`/settings/view-settings/${AES_ENCRYPT_DECRYPT.URLencrypt(RowCellData?.user_uid) || ""}`}> <Avatar sx={{width:"25px", height:"25px", backgroundColor:"#41BA8F"}}><VisibilityOutlinedIcon sx={{ color: "#fff", fontSize:"18px" }} /></Avatar></Link>
                                </Button></Tooltip>


                               
                               {/* <Tooltip title="Edit" arrow slotProps={{tooltip: { sx: {backgroundColor:"#f5f5f9", color: "rgba(0, 0, 0, 0.87)",  border: '1px solid #dadde9', '& .MuiTooltip-arrow':{ color:"#f5f5f9"}}}}}>
                                <Button disabled = {UserTypeAccess == "SUPER_ADMIN" || UserTypeAccess == "ADMIN" ? false : true}
                                size="small" sx={{minWidth:"auto"}} >
                                <Link to={`/settings/view-settings/${AES_ENCRYPT_DECRYPT.URLencrypt(RowCellData?.user_uid) || ""}`} ><Avatar sx={{width:"25px", height:"25px", backgroundColor: UserTypeAccess == "SUPER_ADMIN" || UserTypeAccess == "ADMIN" ? "#E77B18" : "grey"}}><EditIcon sx={{ color: "#fff", fontSize:"18px" }} /></Avatar></Link>
                                </Button>
                                </Tooltip>  */}
                                

                                {/* <Tooltip title="Delete" arrow slotProps={{tooltip: { sx: {backgroundColor:"#f5f5f9", color: "rgba(0, 0, 0, 0.87)",  border: '1px solid #dadde9', '& .MuiTooltip-arrow':{ color:"#f5f5f9"}}}}}>

                                <Button disabled = {UserTypeAccess == "SUPER_ADMIN" || UserTypeAccess == "ADMIN" ? false : true}
                                 size="small" sx={{minWidth:"auto"}}>
                                <Avatar sx={{width:"25px", height:"25px", backgroundColor: UserTypeAccess == "SUPER_ADMIN" || UserTypeAccess == "ADMIN" ?"#BC123E" : "grey"}}><DeleteOutlinedIcon sx={{ color: "#fff", fontSize:"18px" }} /></Avatar>
                                </Button>
                                </Tooltip> */}
                              </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        sx={{ textAlign: { md: "center", xs: "start" } }}
                        colSpan={7}
                      >
                        No Setting Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          {users.total_records > 0 && (
            <TablePagination
              rowsPerPageOptions={[15, 25, 50, 100]}
              component="div"
              count={users.total_records}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Grid>
      </Grid>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loaderopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );

  // TableData.propTypes = {
  //   page: PropTypes.number,
  //   : PropTypes.function,
  //   list: PropTypes.function,
  // };
}
