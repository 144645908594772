import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    appointment_data: "" 
}

export const appointmentData = createSlice({
    name:"storeData",
    initialState,
    reducers:{
        updateAppointmentData:(state, action) =>{
            state.appointment_data = action.payload
            console.log(state);
        }
    }
})

export const { updateAppointmentData } = appointmentData.actions;
export default appointmentData.reducer