import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    settingData:{selectedDays:[],morningChecked:false,  }
}

export const settingSlice = createSlice({
    name:"setting",
    initialState,
    reducers:{
        updateSetting:(state, action) =>{
            state.settingData = action.payload
        }
    }
})


export const {updateSetting} = settingSlice.actions;
export default settingSlice.reducer

